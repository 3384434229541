import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DepartmentCode, PortalId } from '@gk/gk-modules';
import { filter, find, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import {
  MainWebPortalUrl,
  WebPortAlType,
} from '../../modules/office-departments-portal/departments/departments.model';
import {
  PortalSettingsAccess,
  WebPortal,
  WebPortalResponseDto,
} from './web-portal.model';

@Injectable()
export class WebPortalService {
  webPortalUrl = '/api/system/web/portal';
  private webPortalData: Observable<WebPortal[]>;
  private mainWebPortalData: Observable<WebPortal>;
  constructor(private httpClient: HttpClient) {}

  getWebPortalDataByCurrentRoute(
    portalRoute: MainRoutes
  ): Observable<WebPortal> {
    return this.getWebPortalData().pipe(
      mergeMap((data) => data),
      filter(
        (webPortalData) =>
          webPortalData.url && webPortalData.url.includes(portalRoute)
      )
    );
  }

  getMainWebPortalData(): Observable<WebPortal> {
    if (!this.mainWebPortalData) {
      this.mainWebPortalData = this.getWebPortalData().pipe(
        mergeMap((data) => data),
        find((webPortalData) => webPortalData.url === MainWebPortalUrl),
        shareReplay(1)
      );
    }

    return this.mainWebPortalData;
  }

  getWebPortalData(): Observable<WebPortal[]> {
    if (!this.webPortalData) {
      this.webPortalData = this.httpClient
        .get<WebPortalResponseDto[]>(this.webPortalUrl)
        .pipe(
          map((apiFields) =>
            apiFields.map((apiField) => WebPortal.fromApiToApp(apiField))
          ),
          shareReplay(1)
        );
    }
    return this.webPortalData;
  }

  getPortalSettingsAccess(): Observable<PortalSettingsAccess> {
    return this.getWebPortalData().pipe(
      map((webPortalData) =>
        PortalSettingsAccess.getPortalSettingsAccess(webPortalData)
      )
    );
  }

  getGeodesyWebPortals(): Observable<WebPortal[]> {
    return this.getWebPortalData().pipe(
      map((data) =>
        data.filter(
          (webPortal) =>
            webPortal.departmentCode === DepartmentCode.Geodesy &&
            webPortal.portalId !== PortalId.IntraEwid &&
            !webPortal.masterId
        )
      )
    );
  }

  getSpatialInformationWebPortals(): Observable<WebPortal[]> {
    return this.getWebPortalData().pipe(
      map((data) =>
        data.filter(
          (webPortal) =>
            webPortal.type === WebPortAlType.External &&
            webPortal.portalId >= 100
        )
      )
    );
  }
}
