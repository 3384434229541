<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">
      {{ 'BUILDING_PERMIT.NEW_REQUEST.TITLE' | translate }}
    </h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'BUILDING_PERMIT.NEW_REQUEST.INVESTOR_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isConstructionIntentionTypeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ConstructionIntentionType"
          >
            {{
              'BUILDING_PERMIT.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE'
                | translate
            }}
          </label>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <mat-select
                class="form-select form-select-sm required"
                [id]="controlName.ConstructionIntentionType"
                [attr.name]="controlName.ConstructionIntentionType"
                [formControlName]="controlName.ConstructionIntentionType"
                [placeholder]="
                  'BUILDING_PERMIT.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE_PLACEHOLDER'
                    | translate
                "
                multiple
              >
                @for (
                  constructionIntentionType of constructionIntentionTypeDictionaryService.get()
                    | async;
                  track constructionIntentionType.id
                ) {
                  <mat-option [value]="constructionIntentionType.id">
                    {{ constructionIntentionType.name }}
                  </mat-option>
                }
              </mat-select>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p class="text-muted mb-0">
            {{
              'BUILDING_PERMIT.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE_INFO'
                | translate
            }}
          </p>
          @if (!isConstructionIntentionTypeFormControlValid() && submitted) {
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isConstructionProjectNameFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ConstructionProjectName">
            {{
              'BUILDING_PERMIT.NEW_REQUEST.CONSTRUCTION_PROJECT_NAME'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ConstructionProjectName"
                [id]="controlName.ConstructionProjectName"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ConstructionProjectName"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p class="text-muted mb-0">
            {{
              'BUILDING_PERMIT.NEW_REQUEST.CONSTRUCTION_PROJECT_NAME_DESCRIPTION'
                | translate
            }}
          </p>
          @if (!isConstructionProjectNameFormControlValid() && submitted) {
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'BUILDING_PERMIT.NEW_REQUEST.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'BUILDING_PERMIT.NEW_REQUEST.MAP_OBJECTS_REQUIRED' | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-electronic-correspondence-statement
      [formGroup]="formGroup"
      [submitted]="submitted"
    >
    </app-electronic-correspondence-statement>

    <app-attachments
      [eServiceName]="eServiceName"
      [portalId]="portalId"
      [submitted]="submitted"
    >
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{ 'BUILDING_PERMIT.NEW_REQUEST.DECISION_FORM' | translate }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{ 'BUILDING_PERMIT.NEW_REQUEST.NON_ELECTRONIC_FORM' | translate }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{ 'BUILDING_PERMIT.NEW_REQUEST.ELECTRONIC_FORM' | translate }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'BUILDING_PERMIT.NEW_REQUEST.SHIPPING_FORM' | translate }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{
                'BUILDING_PERMIT.NEW_REQUEST.SHIPPING_FORM_REQUIRED' | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section class="my-2">
      <app-cautions [portalId]="portalId"></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'BUILDING_PERMIT.NEW_REQUEST.PROVIDE_REQUIRED_FIELDS' | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary me-2">
        {{ 'BUILDING_PERMIT.NEW_REQUEST.SUBMIT' | translate }}
      </button>

      <button
        type="button"
        [routerLink]="['/office-departments-portal/departments']"
        class="btn btn-outline-primary"
      >
        {{ 'BUILDING_PERMIT.NEW_REQUEST.CANCEL' | translate }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
