import { DictionaryField } from '@gk/gk-modules';
import { Place } from '../place/place.model';

export interface AddressFromApi {
  AdresId: number;
  Numer: string;
  MiejscNazwa: string;
  UlicaNazwa: string;
  UlicaTyp: string;
  Kod: string;
}

export class Address {
  constructor(
    public id: number,
    public number: string,
    public name: string,
    public streetName: string,
    public streetType: string,
    public code: string
  ) {}

  static fromApiToApp(addressFromApi: AddressFromApi): Address {
    return new this(
      addressFromApi.AdresId,
      addressFromApi.Numer,
      addressFromApi.MiejscNazwa,
      addressFromApi.UlicaNazwa,
      addressFromApi.UlicaTyp,
      addressFromApi.Kod
    );
  }

  static fromAppToApiString(address: Address): string {
    return `${address.streetName} ${address.number}, ${address.name}`;
  }

  static fromAppArrayToApiString(addresses: Address[]): string {
    return addresses.reduce(
      (parsedAddresses, address) =>
        `${parsedAddresses}${this.fromAppToApiString(address)}; `,
      ''
    );
  }
}

export class SearchAddressFormModel {
  constructor(
    public place: string | Place,
    public street: string | DictionaryField,
    public ordinalNumber: string
  ) {}
}

export class SearchAddressModel {
  constructor(
    public placeId?: number,
    public streetId?: number,
    public ordinalNumber?: string
  ) {}
}

export enum AddressListMode {
  Choosing = 0,
  Removing = 1,
}
