<div
  class="position-relative login-form d-flex flex-column justify-content-center pb-3 overflow-visible"
>
  <div class="center-blank-element"></div>
  <div class="static-height-container">
    @if (
      infoAlertsVisible &&
      externalLoginUrl &&
      isPzAuthenticationAvailable &&
      (defaultConfigGuest?.isPzConfigured ||
        defaultConfigGuest?.isLoginGovConfigured)
    ) {
      <div class="row main-content mt-2 p-2">
        <div class="col p-0 d-md-none">
          <gk-session-tool-info
            [infoText]="
              'SESSION_TOOLS.LOGIN.FORMS_INFO_BOTH_PERSONS_LOGIN' | translate
            "
          ></gk-session-tool-info>
        </div>
        <div class="col-md-7 p-0 d-none d-md-flex">
          <gk-session-tool-info
            [infoText]="
              'SESSION_TOOLS.LOGIN.FORMS_INFO_LEGAL_PERSON_LOGIN' | translate
            "
          ></gk-session-tool-info>
        </div>
        <div class="col-md-4 offset-md-1 p-0 d-none d-md-flex">
          <gk-session-tool-info
            [infoText]="
              'SESSION_TOOLS.LOGIN.FORMS_INFO_NATURAL_PERSON_LOGIN' | translate
            "
          ></gk-session-tool-info>
        </div>
      </div>
    }
    <div
      class="row main-content text-center p-2"
      [ngClass]="{
        'without-external-login':
          (!externalLoginUrl &&
            !defaultConfigGuest?.isPzConfigured &&
            !defaultConfigGuest?.isLoginGovConfigured) ||
          !isPzAuthenticationAvailable
      }"
    >
      <div
        id="login-wrapper"
        class="col action-window col-md-7 pb-2 border-radius-top-rounded-media"
        [ngClass]="{
          'border-radius-top-rounded':
            isFormControlReadyToShowAlert(controlNameEnum.Password) ||
            isFormControlReadyToShowAlert(controlNameEnum.Login) ||
            messageText,
          'w-100':
            (!externalLoginUrl &&
              !defaultConfigGuest?.isPzConfigured &&
              !defaultConfigGuest?.isLoginGovConfigured) ||
            !isPzAuthenticationAvailable
        }"
      >
        <div class="position-relative">
          <div class="row mt-3">
            <div class="col-12">
              <h1>{{ 'SESSION_TOOLS.LOGIN.HEADER' | translate }}</h1>
            </div>
          </div>
          <div class="row">
            <form
              autocomplete="on"
              class="form-group col-12 mb-0"
              id="login-form"
              [formGroup]="formGroup"
              (ngSubmit)="loginSubmitHandle()"
            >
              <div class="row">
                <label [attr.for]="controlNameEnum.Login" class="sr-only">{{
                  'SESSION_TOOLS.USER_NAME' | translate
                }}</label>
                <input
                  [formControlName]="controlNameEnum.Login"
                  [attr.name]="controlNameEnum.Login"
                  [attr.id]="controlNameEnum.Login"
                  type="text"
                  class="form__input"
                  [attr.placeholder]="
                    'SESSION_TOOLS.ENTER_USER_NAME' | translate
                  "
                />
              </div>
              <div class="row password-group">
                <label [attr.for]="controlNameEnum.Password" class="sr-only">{{
                  'SESSION_TOOLS.LOGIN.PASSWORD' | translate
                }}</label>
                <input
                  [formControlName]="controlNameEnum.Password"
                  [attr.name]="controlNameEnum.Password"
                  [attr.id]="controlNameEnum.Password"
                  [attr.type]="passwordVisibility ? 'text' : 'password'"
                  class="form__input form-control"
                  [ngClass]="{
                    'password-mask-size': !passwordVisibility
                  }"
                  [attr.placeholder]="
                    'SESSION_TOOLS.LOGIN.ENTER_PASSWORD' | translate
                  "
                />
                <div
                  class="password-addon p-0"
                  [ngClass]="{ 'text-dark': passwordVisibility }"
                  (mousedown)="togglePasswordVisibility(true)"
                  (mouseup)="togglePasswordVisibility(false)"
                >
                  <i
                    class="fa"
                    [ngClass]="passwordVisibility ? 'fa-eye' : 'fa-eye-slash'"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  @if (!actionRequestInProgress) {
                    <input
                      type="submit"
                      id="login-button"
                      class="btn btn-normal ready-info"
                      [attr.value]="'SESSION_TOOLS.LOGIN.LOGIN' | translate"
                    />
                  } @else {
                    <button
                      type="button"
                      class="btn btn-normal pending-info"
                      [attr.title]="
                        'SESSION_TOOLS.LOGIN.LOGIN_IN_PROGRESS' | translate
                      "
                    >
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
          <div class="row mb-1">
            @if (signUpLinkVisible) {
              <div id="sign-up-wrapper" class="col-12">
                <p class="mb-0">
                  {{ 'SESSION_TOOLS.LOGIN.DO_NOT_HAVE_ACCOUNT' | translate }}
                  <a href="pz/user-form" class="register-link">{{
                    'SESSION_TOOLS.LOGIN.SIGN_UP' | translate
                  }}</a>
                </p>
              </div>
            }
            @if (restorePasswordLinkVisible) {
              <div class="col-12 password-reset-link-wrapper">
                <a
                  href="#"
                  (click)="openResetPasswordWindow($event)"
                  id="password-reset-link"
                  >{{
                    'SESSION_TOOLS.LOGIN.DO_NOT_REMEMBER_PASSWORD' | translate
                  }}</a
                >
              </div>
            }
          </div>
          @if (tfaService.isTfaWebAuthnInOperation() | async) {
            <div class="row mb-1">
              <div class="col-12">
                <a href="" (click)="openResetTfaCodeWindow($event)">{{
                  'SESSION_TOOLS.TFA_BASE_CONFIRMATION.RESTORE_ACCESS_WITH_RESET_CODE'
                    | translate
                }}</a>
              </div>
            </div>
          }
        </div>
      </div>

      @if (
        externalLoginUrl &&
        isPzAuthenticationAvailable &&
        (defaultConfigGuest?.isPzConfigured ||
          defaultConfigGuest?.isLoginGovConfigured)
      ) {
        <div class="col-separation align-items-center flex-column col-md-1">
          <div class="separation-line"></div>
          <div class="separation-square-text-box">
            {{ 'SESSION_TOOLS.LOGIN.OR' | translate | uppercase }}
          </div>
          <div class="separation-line"></div>
        </div>
      }
      @if (
        externalLoginUrl &&
        isPzAuthenticationAvailable &&
        (defaultConfigGuest?.isPzConfigured ||
          defaultConfigGuest?.isLoginGovConfigured)
      ) {
        <div
          class="d-flex flex-center justify-content-center col-md-4 col-sm-12 text-center external-login order-2 order-md-0 pb-2 pt-2 border-radius-bottom-rounded-media"
        >
          <div
            id="trusted-profile-login-wrapper"
            class="d-flex align-items-center justify-content-center"
            tabindex="0"
          >
            <button
              (click)="openExternalLoginPage()"
              class="choose-usage-button trusted-profile-button"
              tabindex="-1"
            >
              {{ 'SESSION_TOOLS.LOGIN.LOGIN_BY' | translate }}
              <span
                ><img
                  class="pz-img"
                  [src]="externalLoginLogoAssetUrl"
                  [attr.alt]="
                    'SESSION_TOOLS.LOGIN.EXTERNAL_LOGIN_LOGO_ALT' | translate
                  "
              /></span>
            </button>
            <i class="fa fa-chevron-right chevron-icon text-dark p-1"></i>
          </div>
        </div>
      }
      @if (messageText) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 box-shadow-for-wrapper border-radius-bottom-media"
          [ngClass]="{
            'bg-warning': messageStatus === messageStatusEnum.Warning,
            'bg-normal': messageStatus === messageStatusEnum.Success,
            'bg-danger': messageStatus === messageStatusEnum.Error,
            'text-dark': messageStatus === messageStatusEnum.Warning,
            'text-white':
              messageStatus === messageStatusEnum.Error ||
              messageStatus === messageStatusEnum.Success,
            'col-md-7':
              externalLoginUrl &&
              isPzAuthenticationAvailable &&
              (defaultConfigGuest?.isPzConfigured ||
                defaultConfigGuest?.isLoginGovConfigured)
          }"
        >
          <p class="mb-0 fw-bold">
            <i
              class="fa"
              [ngClass]="{
                'fa-exclamation-triangle':
                  messageStatus === messageStatusEnum.Warning,
                'fa-check': messageStatus === messageStatusEnum.Success,
                'fa-lock': messageStatus === messageStatusEnum.Error,
                'text-white':
                  messageStatus === messageStatusEnum.Error ||
                  messageStatus === messageStatusEnum.Success
              }"
            >
            </i>
            {{ messageText }}
          </p>
        </div>
      }

      @if (isFormControlReadyToShowAlert(controlNameEnum.Login)) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 bg-warning text-dark box-shadow-for-wrapper col-md-7 border-radius-bottom-media"
        >
          <p class="mb-0 fw-bold">
            <i class="fa fa-exclamation-triangle"></i>
            @if (shouldShowRequiredMessage(controlNameEnum.Login)) {
              {{ 'SESSION_TOOLS.LOGIN_REQUIRED' | translate }}
            }
            @if (shouldShowMinLengthMessage(controlNameEnum.Login)) {
              {{ 'SESSION_TOOLS.LOGIN_TOO_SHORT' | translate }}
            }
          </p>
        </div>
      } @else {
        @if (isFormControlReadyToShowAlert(controlNameEnum.Password)) {
          <div
            class="col-12 text-center d-flex justify-content-center py-3 bg-warning text-dark box-shadow-for-wrapper col-md-7 border-radius-bottom-media"
          >
            <p class="mb-0 fw-bold">
              <i class="fa fa-exclamation-triangle"></i>
              @if (shouldShowRequiredMessage(controlNameEnum.Password)) {
                {{ 'SESSION_TOOLS.LOGIN.PASSWORD_REQUIRED' | translate }}
              }
              @if (shouldShowMinLengthMessage(controlNameEnum.Password)) {
                {{ 'SESSION_TOOLS.LOGIN.PASSWORD_TOO_SHORT' | translate }}
              }
            </p>
          </div>
        }
      }
    </div>
  </div>
  <div class="last-blank-element"></div>
</div>

<ng-template gkTfaConfirmationHost></ng-template>
