export interface ApiDocType {
  Id: number;
  Nazwa: string;
  Prefix: string;
  NazdokId?: number;
  IsObligatory?: boolean;
  PortalProjektanta: 1 | 2;
  MaxSize?: number;
}

export class DocType<T = number> {
  public labelKey?: string;
  public downloadLabelKey?: string;
  public uploadAvailable = true;
  constructor(
    public id: T,
    public name: string,
    public sqlPattern?: SqlPattern,
    public availableForDesigner?: boolean,
    public requiredForDesigner?: boolean,
    public isObligatory?: boolean,
    public matchingRequestFileId?: string,
    public maxSize?: number,
  ) {}

  static fromApiToApp(apiDocType: ApiDocType): DocType {
    return new this(
      apiDocType.Id,
      apiDocType.Nazwa,
      apiDocType.Prefix,
      apiDocType.PortalProjektanta > 0,
      apiDocType.PortalProjektanta === 1,
      apiDocType.IsObligatory,
      undefined,
      apiDocType.MaxSize,
    );
  }
}

export const powerOfAttorneyDocTypeId = 10035;

export type SqlPattern = string | null;
/*
  possible patterns:
 'ane%' - startsWith
 '%dz%' - contains
 '%.zip' - endsWith
*/

export enum EServiceDocTypeName {
  RoadExit = 'EuslDr4',
  EuWylGrRol1 = 'EuWylGrRol1',
  LaneOccupancyBuildingWorks = 'EuslDr1',
  EuWylGrRol2 = 'EuWylGrRol2',
  LaneOccupancyInfrastructureDevices = 'EuslDr2',
  LaneOccupancyBuildingObjects = 'EuslDr3',
  EuDlkUpul = 'EuDlkUpul',
  EuKartaWedk = 'EuKartaWedk',
  EuJednPlyw = 'EuJednPlyw',
  EuZglBud = 'EuZglBud',
  EuZglRozb = 'EuZglRozb',
  EuPrzenPozwBud = 'EuPrzenPozwBud',
  EuSamodzLok = 'EuSamodzLok',
  EuWycinka = 'EuWycinka',
  EuDzBud = 'EuDzBud',
  EuMpzp = 'EuMpzp',
  PropertyLease = 'EuWydzierKSp',
  SupplementaryParcelPurchase = 'EuZakupDz',
  OwnershipConversion = 'EuWykupUw',
  AddressNumberRequest = 'EuNadNradr',
  StreetNaming = 'EuNadNazwy',
  CommunalPremisePurchase = 'EuNabLok',
  LBRCNNewDataChangesNotification = 'EuZmDaneEgib',
  SCOLNewCarryOutRequest = 'EuGlebKlasyf',
  PCLBRPNewCommentsRequest = 'EuUwagiProjekt',
  ATIOEAIPNewRequest = 'EuInfoSrod',
  BPNewRequest = 'EuPozwBud',
  DPNewRequest = 'EuPozwRozb',
  BOPUCNewRequest = 'EuZmUzytBud',
  TSBPNewRequest = 'EuPozwTymczBud',
  BPMDNewRequest = 'EuZmDecPozwBud',
  NONROTNewRequest = 'EuPrzenPraw',
  PLDACPSDNewRequest = 'EuZatwProjZagospDz',
  APNEDNewRequest = 'EuWejscieSasiednNier',
  SFB70CCNewRequest = 'EuZglBudJedn70m2',
  SFBCCNewRequest = 'EuZglBudJedn',
}

export const eServiceI18nPrefix = {
  [EServiceDocTypeName.RoadExit]: 'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST',
  [EServiceDocTypeName.EuWylGrRol1]:
    'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST',
  [EServiceDocTypeName.LaneOccupancyBuildingWorks]:
    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS',
  [EServiceDocTypeName.EuWylGrRol2]:
    'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.NON_AGRICULTURAL_LAND_USE_REQUEST',
  [EServiceDocTypeName.LaneOccupancyInfrastructureDevices]:
    'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES',
  [EServiceDocTypeName.LaneOccupancyBuildingObjects]:
    'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS',
  [EServiceDocTypeName.EuDlkUpul]:
    'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN',
  [EServiceDocTypeName.EuKartaWedk]: 'FISHING_CARD',
  [EServiceDocTypeName.EuJednPlyw]: 'WATER_EQUIPMENT',
  [EServiceDocTypeName.EuZglBud]:
    'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION',
  [EServiceDocTypeName.EuZglRozb]:
    'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION',
  [EServiceDocTypeName.EuPrzenPozwBud]: 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST',
  [EServiceDocTypeName.EuSamodzLok]:
    'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION',
  [EServiceDocTypeName.EuWycinka]:
    'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING',
  [EServiceDocTypeName.EuDzBud]: 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG',
  [EServiceDocTypeName.EuMpzp]:
    'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST',
  [EServiceDocTypeName.PropertyLease]: 'PROPERTY_LEASE',
  [EServiceDocTypeName.SupplementaryParcelPurchase]:
    'SUPPLEMENTARY_PARCEL_PURCHASE',
  [EServiceDocTypeName.OwnershipConversion]: 'OWNERSHIP_CONVERSION',
  [EServiceDocTypeName.AddressNumberRequest]: 'ADDRESS_NUMBER_REQUEST_PORTAL',
  [EServiceDocTypeName.StreetNaming]: 'STREET_NAMING',
  [EServiceDocTypeName.CommunalPremisePurchase]: 'COMMUNAL_PREMISE_PURCHASE',
  [EServiceDocTypeName.LBRCNNewDataChangesNotification]:
    'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION',
  [EServiceDocTypeName.SCOLNewCarryOutRequest]:
    'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST',
  [EServiceDocTypeName.PCLBRPNewCommentsRequest]:
    'PRESENTATION_COMMENTS_LAND_BUILDING_REGISTRY_PROJECT.NEW_COMMENTS_REQUEST',
  [EServiceDocTypeName.ATIOEAIPNewRequest]:
    'ACCESS_TO_INFORMATION_ON_ENVIRONMENT_AND_ITS_PROTECTION.NEW_REQUEST',
  [EServiceDocTypeName.BPNewRequest]: 'BUILDING_PERMIT.NEW_REQUEST',
  [EServiceDocTypeName.DPNewRequest]: 'DEMOLITION_PERMIT.NEW_REQUEST',
  [EServiceDocTypeName.BOPUCNewRequest]:
    'BUILDING_OR_PART_USE_CHANGE.NEW_REQUEST',
  [EServiceDocTypeName.TSBPNewRequest]:
    'TEMPORARY_STRUCTURE_BUILDING_PERMIT.NEW_REQUEST',
  [EServiceDocTypeName.BPMDNewRequest]:
    'BUILDING_PERMIT_MODIFICATION_DECISION.NEW_REQUEST',
  [EServiceDocTypeName.NONROTNewRequest]:
    'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST',
  [EServiceDocTypeName.PLDACPSDNewRequest]:
    'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST',
  [EServiceDocTypeName.APNEDNewRequest]:
    'ADJACENT_PROPERTY_NEED_ENTER_DECISION.NEW_REQUEST',
  [EServiceDocTypeName.SFB70CCNewRequest]:
    'SINGLE_FAMILY_BUILDING_70_CONSTRUCTION_CONVERSION.NEW_REQUEST',
  [EServiceDocTypeName.SFBCCNewRequest]:
    'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST',
};
