<div>
  @switch (controlConfig.valueType) {
    @case (valueTypeEnum.Text) {
      <div>
        <gk-control-text
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-text>
      </div>
    }
    @case (valueTypeEnum.Number) {
      <div>
        <gk-control-number
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-number>
      </div>
    }
    @case (valueTypeEnum.Bool) {
      <div>
        <gk-control-bool
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-bool>
      </div>
    }
    @case (valueTypeEnum.Link) {
      <div>
        <gk-control-link
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-link>
      </div>
    }
    @case (valueTypeEnum.StaticLink) {
      <div>
        <gk-control-static-link
          [controlConfig]="controlConfig"
        ></gk-control-static-link>
      </div>
    }
    @case (valueTypeEnum.Object) {
      <div>
        <gk-control-object
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-object>
      </div>
    }
    @case (valueTypeEnum.ObjectDtoDetails) {
      <div>
        <gk-control-object-dto-details
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-object-dto-details>
      </div>
    }
    @case (valueTypeEnum.Array) {
      <div>
        <gk-control-array
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-array>
      </div>
    }
    @case (valueTypeEnum.DateTime) {
      <div>
        <gk-control-date-time
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-date-time>
      </div>
    }
    @case (valueTypeEnum.Dict) {
      <div>
        <gk-control-dict
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-dict>
      </div>
    }
    @case (valueTypeEnum.ObjectPosition) {
      <div>
        <gk-control-object-position
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-object-position>
      </div>
    }
    @case (valueTypeEnum.GetDataAndRender) {
      <div>
        <gk-control-get-data-and-render
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-get-data-and-render>
      </div>
    }
    @case (valueTypeEnum.File) {
      <div>
        <gk-control-file
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-file>
      </div>
    }
    @case (valueTypeEnum.AddRelatedDocument) {
      <div>
        <gk-control-add-related-document
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-add-related-document>
      </div>
    }
    @case (valueTypeEnum.CancelRequest) {
      <div>
        <gk-control-cancel-request
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-cancel-request>
      </div>
    }
    @case (valueTypeEnum.TabstripBookmark) {
      <div>
        <gk-control-tabstrip-bookmark
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-tabstrip-bookmark>
      </div>
    }
    @case (valueTypeEnum.DocumentsList) {
      <div>
        <gk-control-documents-list
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-documents-list>
      </div>
    }
    @case (valueTypeEnum.Separator) {
      <div>
        <gk-control-separator
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-separator>
      </div>
    }
    @case (valueTypeEnum.Title) {
      <div>
        <gk-control-title
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-title>
      </div>
    }
    @case (valueTypeEnum.NestedAccordion) {
      <div>
        <gk-control-nested-accordion
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-nested-accordion>
      </div>
    }
    @case (valueTypeEnum.OrderPayment) {
      <div>
        <gk-control-payment
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-payment>
      </div>
    }
    @case (valueTypeEnum.Modal) {
      <div>
        <gk-control-modal
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-modal>
      </div>
    }
    @case (valueTypeEnum.Table) {
      <div>
        <gk-control-table
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-table>
      </div>
    }
    @case (valueTypeEnum.TextModal) {
      <div>
        <gk-control-text-modal
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-text-modal>
      </div>
    }
    @case (valueTypeEnum.StaticDict) {
      <div>
        <gk-control-static-dict
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-static-dict>
      </div>
    }
    @case (valueTypeEnum.MapNavigationToMapObject) {
      <div>
        <gk-control-map-navigation-to-map-object
          [data]="data"
          [controlConfig]="controlConfig"
          (dispatchMapAction)="dispatchMapAction.emit($event)"
        ></gk-control-map-navigation-to-map-object>
      </div>
    }
    @case (valueTypeEnum.InputFile) {
      <div>
        <gk-control-input-file
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-input-file>
      </div>
    }
    @case (valueTypeEnum.UtilityInfrastructure) {
      <div>
        <gk-control-utility-infrastructure
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-utility-infrastructure>
      </div>
    }
    @case (valueTypeEnum.Area) {
      <div>
        <gk-control-area
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-area>
      </div>
    }
    @case (valueTypeEnum.ExpandibleTitle) {
      <div>
        <gk-control-expandible-title
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-expandible-title>
      </div>
    }
    @case (valueTypeEnum.FilterAndRender) {
      <div>
        <gk-control-filter-and-render
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-filter-and-render>
      </div>
    }
    @case (valueTypeEnum.CancelPayment) {
      <div>
        <gk-control-cancel-payment
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-cancel-payment>
      </div>
    }
    @case (valueTypeEnum.AppActionLink) {
      <div>
        <gk-control-app-action-link
          [data]="data"
          [controlConfig]="controlConfig"
          (dispatchMapAction)="dispatchMapAction.emit($event)"
        ></gk-control-app-action-link>
      </div>
    }
    @case (valueTypeEnum.TextWithButtons) {
      <div>
        <gk-control-text-with-buttons
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-text-with-buttons>
      </div>
    }
    @case (valueTypeEnum.TextWithCallbackButton) {
      <div>
        <gk-control-text-with-callback-button
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-text-with-callback-button>
      </div>
    }
    @default {
      <div>
        <gk-control-default
          [data]="data"
          [controlConfig]="controlConfig"
        ></gk-control-default>
      </div>
    }
  }
</div>
