import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { PortalIds } from './portal-ids.model';

@Injectable()
export class PortalService {
  portalIds = new ReplaySubject<PortalIds>();

  constructor() {
    this.getAndEmitPortalIds();
  }

  private getAndEmitPortalIds(): void {
    this.portalIds.next(new PortalIds());
  }
}
