import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PzService,
  StreetService,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DocType } from '../../../services/doc-type/doc-type.model';
import {
  TreesAndShrubsFellingDocTypeId,
  TreesAndShrubsFellingDocTypeName,
} from '../../../services/environmental-protection/trees-and-shrubs-felling-doc-type/trees-and-shrubs-felling-doc-type.model';
import { TreesAndShrubsFellingDocTypeService } from '../../../services/environmental-protection/trees-and-shrubs-felling-doc-type/trees-and-shrubs-felling-doc-type.service';
import {
  FellingObjectsControlName,
  FellingPurpose,
  TreesAndShrubsFellingControlName,
} from '../../../services/environmental-protection/trees-and-shrubs-felling-form/trees-and-shrubs-felling-form.model';
import { TreesAndShrubsFellingFormService } from '../../../services/environmental-protection/trees-and-shrubs-felling-form/trees-and-shrubs-felling-form.service';
import {
  TreesAndShrubsFelling,
  TreesAndShrubsFellingRequestDto,
} from '../../../services/environmental-protection/trees-and-shrubs-felling/trees-and-shrubs-felling.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import { DocFile } from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { PortalService } from '../../../services/portal/portal.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-trees-and-shrubs-felling',
  templateUrl: './trees-and-shrubs-felling-request.component.html',
})
export class TreesAndShrubsFellingRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  override controlName = TreesAndShrubsFellingControlName;
  proxyDetailsVisible = false;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25'
  );
  formValue: TreesAndShrubsFellingRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  chosenFiles: {
    [key in TreesAndShrubsFellingDocTypeId]?: DocFile[];
  } = {};
  proxyDetailsChecked: boolean;
  fellingObjectsControlName = FellingObjectsControlName;
  fellingPurpose = FellingPurpose;

  constructor(
    private treesAndShrubsFellingFormService: TreesAndShrubsFellingFormService,
    public override mapStateService: MapStateService,
    public treesAndShrubsFellingDocTypeService: TreesAndShrubsFellingDocTypeService,
    public override mapSettingsService: MapSettingsService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private investorDetailsFormService: InvestorDetailsFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override streetService: StreetService,
    private portalService: PortalService,
    public override pzService: PzService
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.fetchApplicantMapSettings();
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.subscribeToDecisionFormRadioFormControl();
    this.fetchWrongFileText();
    this.fetchAndSetPortalId();
  }

  createForm(): void {
    this.formGroup = this.treesAndShrubsFellingFormService.getFormGroup();
    this.disableOrEnableStreetFormControlOnStreetAbsenceCheckboxChange();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.VALIDATION.FORM_NOT_VALID_ERROR'
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation)
      );
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.proxyDetailsChecked = proxyDetailsChecked;
        this.treesAndShrubsFellingDocTypeService.getOrUpdateObligatoryDocTypes(
          proxyDetailsChecked
        );
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  fetchAndSetPortalId(): void {
    this.portalService.portalIds
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalIds) => {
        this.portalId =
          portalIds.environmentalProtectionPortalTreesAndShrubsFellingRequest;
      });
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean
  ): void {
    if (proxyDetailsChecked) {
      this.formGroup.addControl(
        TreesAndShrubsFellingControlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup()
      );
    } else {
      this.formGroup.removeControl(
        TreesAndShrubsFellingControlName.ProxyDetails
      );
    }
    this.toggleProxyDetails();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewEnvironmentalProtectionPortalRequest,
      this.mapStateService.getViewState(
        MapId.NewEnvironmentalProtectionPortalRequest,
        mapSettings
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewEnvironmentalProtectionPortalRequest,
        mapSettings,
        MapPortalName.Applicant
      ),
      [this.mapObjectTableState]
    );
  }

  toggleProxyDetails(): void {
    this.proxyDetailsVisible = !this.proxyDetailsVisible;
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error()
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.investorDetailsComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            TreesAndShrubsFellingRequestDto.fromAppToApi(
              this.getFormValue(),
              this.getParcelMapObject(),
              this.getIssueRangesMapObjects(),
              apiDocuments
            ),
            '/api/interesant/wniosek/wycinkaDrzewIKrzewow/addToSign'
          )
        ),
        takeWhile(() => this.isAlive)
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest}/requests-list`
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.areIssueRangesChosen() &&
      this.areDocumentsValid()
    );
  }

  areIssueRangesChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon
    );
  }

  getFormValue(): TreesAndShrubsFelling {
    return {
      ...this.formGroup.value,
      landParcel: this.getParcelMapObject(),
    };
  }

  getParcelMapObject(): EgibObject[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || []).filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      )
    );
  }

  getIssueRangesMapObjects(): MapObject[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || []).filter(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon
    );
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile)
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.chosenFiles));
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.InvestorDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.ProxyDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.ProxyDetailsCheckbox
    ) as UntypedFormControl;
  }

  areDocumentsValid(): boolean {
    return this.treesAndShrubsFellingDocTypeService.areObligatoryDocFilesAttached(
      this.getChosenFiles()
    );
  }

  checkInvestorDetailsFormGroupValidAndFormValidated(): boolean {
    return !this.getInvestorDetailsFormGroup().valid && this.submitted;
  }

  checkProxyDetailsFormGroupValidAndFormValidated(): boolean {
    return (
      this.getProxyDetailsFormGroup() &&
      !this.getProxyDetailsFormGroup().valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  checkParcelsAndIssueRangeValidAndFormValidated(): boolean {
    return (
      (!this.areParcelsChosen() || !this.areIssueRangesChosen()) &&
      this.submitted
    );
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<TreesAndShrubsFellingDocTypeId>
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  areChosenObligatoryDocuments(
    docType: DocType<TreesAndShrubsFellingDocTypeId>
  ): boolean {
    const files =
      this.chosenFiles[docType.id] && this.chosenFiles[docType.id].length
        ? this.chosenFiles[docType.id].filter(
            (file) =>
              file.docDescription !==
              TreesAndShrubsFellingDocTypeName.ProxyStampDuty
          )
        : undefined;

    return (
      !this.treesAndShrubsFellingDocTypeService.obligatoryDocTypes.find(
        (obligatoryDocType) => obligatoryDocType.id === docType.id
      ) || !!(files && files.length)
    );
  }

  handleFileInputAction(
    id: TreesAndShrubsFellingDocTypeId,
    files: DocFile[]
  ): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(id: TreesAndShrubsFellingDocTypeId, files: DocFile[]): File[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  checkDocTypeIsRequired(id: number): DocType {
    return this.treesAndShrubsFellingDocTypeService.obligatoryDocTypes.find(
      (obligatoryDocType) => obligatoryDocType.id === id
    );
  }

  checkTreeFormGroupValidAndFormValidated(index: number): boolean {
    return !this.getTreeFormGroup(index).valid && this.submitted;
  }

  getTreeInventoryNumberFormControl(index: number): UntypedFormControl {
    return this.getTreeFormGroup(index).get(
      FellingObjectsControlName.InventoryNumber
    ) as UntypedFormControl;
  }

  getTreeSpeciesNameFormControl(index: number): UntypedFormControl {
    return this.getTreeFormGroup(index).get(
      FellingObjectsControlName.SpeciesName
    ) as UntypedFormControl;
  }

  getTreeTrunkCircumferenceFormControl(index: number): UntypedFormControl {
    return this.getTreeFormGroup(index).get(
      FellingObjectsControlName.TrunkCircumference
    ) as UntypedFormControl;
  }

  getTreeFormGroup(index: number): UntypedFormGroup {
    return this.getTreesListFormArray().controls[index] as UntypedFormGroup;
  }

  deleteTreeFormControl(index: number): void {
    this.getTreesListFormArray().removeAt(index);
  }

  areSomeTreesOrShrubsFormGroups(): boolean {
    return this.areSomeTreesFormGroups() || this.areSomeShrubsFormGroups();
  }

  areSomeTreesFormGroups(): boolean {
    return !!this.getTreesListFormArray().controls.length;
  }

  areSomeShrubsFormGroups(): boolean {
    return !!this.getShrubsListFormArray().controls.length;
  }

  addTreeFormControl(): void {
    this.getTreesListFormArray().push(
      this.treesAndShrubsFellingFormService.getTreeFormGroup()
    );
  }

  getTreesListFormArray(): UntypedFormArray {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.TreesList
    ) as UntypedFormArray;
  }

  checkShrubFormGroupValidAndFormValidated(index: number): boolean {
    return !this.getShrubFormGroup(index).valid && this.submitted;
  }

  getShrubInventoryNumberFormControl(index: number): UntypedFormControl {
    return this.getShrubFormGroup(index).get(
      FellingObjectsControlName.InventoryNumber
    ) as UntypedFormControl;
  }

  getShrubSpeciesNameFormControl(index: number): UntypedFormControl {
    return this.getShrubFormGroup(index).get(
      FellingObjectsControlName.SpeciesName
    ) as UntypedFormControl;
  }

  getShrubProjectionSurfaceFormControl(index: number): UntypedFormControl {
    return this.getShrubFormGroup(index).get(
      FellingObjectsControlName.ProjectionSurface
    ) as UntypedFormControl;
  }

  getShrubFormGroup(index: number): UntypedFormGroup {
    return this.getShrubsListFormArray().controls[index] as UntypedFormGroup;
  }

  deleteShrubFormControl(index: number): void {
    this.getShrubsListFormArray().removeAt(index);
  }

  addShrubFormControl(): void {
    this.getShrubsListFormArray().push(
      this.treesAndShrubsFellingFormService.getShrubFormGroup()
    );
  }

  getShrubsListFormArray(): UntypedFormArray {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.ShrubsList
    ) as UntypedFormArray;
  }

  checkFellingCauseFormControlValidAndFormValidated(): boolean {
    return !this.getFellingCauseFormControl().valid && this.submitted;
  }

  getFellingCauseFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.FellingCause
    ) as UntypedFormControl;
  }

  checkFellingDateFormControlValidAndFormValidated(): boolean {
    return !this.getFellingDateFormControl().valid && this.submitted;
  }

  getFellingDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.FellingDate
    ) as UntypedFormControl;
  }

  checkInvestmentDescriptionFormControlValidAndFormValidated(): boolean {
    return !this.getInvestmentDescriptionFormControl().valid && this.submitted;
  }

  getInvestmentDescriptionFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.InvestmentDescription
    ) as UntypedFormControl;
  }

  checkGrowthPlaceFormControlValidAndFormValidated(): boolean {
    return !this.getGrowthPlaceFormControl().valid && this.submitted;
  }

  getGrowthPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.GrowthPlace
    ) as UntypedFormControl;
  }

  checkBusinessPurposeFormControlValidAndFormValidated(): boolean {
    return !this.getBusinessPurposeFormControl().valid && this.submitted;
  }

  getBusinessPurposeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.BusinessPurpose
    ) as UntypedFormControl;
  }

  isLocationFormGroupValid(): boolean {
    return (
      this.isLandRegisterFormControlValid() &&
      this.isPlaceFormControlValid() &&
      this.isStreetFormControlValid()
    );
  }

  isStreetFormControlValid(): boolean {
    const streetFormControl = this.getStreetFormControl();
    return streetFormControl.disabled || streetFormControl.valid;
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  override getPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.Place
    ) as UntypedFormControl;
  }

  isLandRegisterFormControlValid(): boolean {
    return this.getLandRegisterFormControl().valid;
  }

  getLandRegisterFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.LandRegister
    ) as UntypedFormControl;
  }

  getLandNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.LandNumber
    ) as UntypedFormControl;
  }

  disableOrEnableStreetFormControlOnStreetAbsenceCheckboxChange(): void {
    const streetAbsenceFormControl = this.getStreetAbsenceFormControl();
    if (!streetAbsenceFormControl) {
      return;
    }
    const streetFormControl = this.getStreetFormControl();
    streetAbsenceFormControl.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (value) {
          streetFormControl.disable();
        } else if (this.getStreetAbsenceFormControl().enabled) {
          streetFormControl.enable();
        }
      });
  }

  getStreetAbsenceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TreesAndShrubsFellingControlName.StreetAbsence
    ) as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
