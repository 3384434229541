import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { GkMapSheetFormControlName } from './gk-map-sheet-form.model';
import { FormFieldType, FormFieldWrappers } from '../../formly';
import { Paper, PaperOptionProperty, Scale } from '../../../gk-map/models';
import { mapSheetScales } from '../../../gk-map/configs';
import { FormlyFieldKendoSelectComponentProps } from '../../formly/custom-types/select.type';
import { GkMapSheetFormComponent } from './gk-map-sheet-form.component';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';

@Injectable()
export class GkMapSheetFormService {
  private getUniqueOrientations(papers: Paper[]): string[] {
    return _.uniq(_.map(papers, 'orientation'));
  }

  getFormConfig(gkMapSheetFormComponent: GkMapSheetFormComponent): {
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  } {
    const fields = [
      {
        wrappers: [FormFieldWrappers.KendoFormField],
        key: GkMapSheetFormControlName.PaperOrientation,
        type: FormFieldType.KendoSelect,
        props: {
          label: 'GK.MAP.ORIENTATION',
          options: this.getUniqueOrientations(
            gkMapSheetFormComponent.mapState.toolbarState.papers,
          ),
          valuePrimitive: true,
        } as FormlyFieldKendoSelectComponentProps,
        hooks: {
          onInit: (field: FormlyFieldConfig): void => {
            field.options.fieldChanges
              .pipe(
                filter((e) => e.type === 'valueChanges' && e.field === field),
              )
              .subscribe((e) => {
                gkMapSheetFormComponent.handleMapSheetPaperOrientationChange(
                  e.value,
                );
              });
          },
        },
      },
      {
        wrappers: [FormFieldWrappers.KendoFormField],
        key: GkMapSheetFormControlName.Paper,
        type: FormFieldType.KendoSelect,
        props: {
          label: 'GK.MAP.PAPER_SIZE',
          valuePrimitive: false,
          valueProp: PaperOptionProperty.Name,
          labelProp: PaperOptionProperty.Name,
        } as FormlyFieldKendoSelectComponentProps,
        expressions: {
          'props.options': (field: FormlyFieldConfig): Paper[] =>
            gkMapSheetFormComponent.mapState.toolbarState.papers.filter(
              (paper) => paper.orientation === field.model.paperOrientation,
            ),
        },
        hooks: {
          onInit: (field: FormlyFieldConfig): void => {
            field.options.fieldChanges
              .pipe(
                filter((e) => e.type === 'valueChanges' && e.field === field),
              )
              .subscribe((e) => {
                gkMapSheetFormComponent.handleMapSheetPaperNameChange(
                  (e.value as Paper).name,
                );
              });
          },
        },
      },
      {
        wrappers: [FormFieldWrappers.KendoFormField],
        key: GkMapSheetFormControlName.Scale,
        type: FormFieldType.KendoSelect,
        props: {
          label: 'GK.MAP.SCALE',
          valuePrimitive: false,
          options: [...mapSheetScales],
          valueProp: PaperOptionProperty.Name,
          labelProp: PaperOptionProperty.Name,
        } as FormlyFieldKendoSelectComponentProps,
        hooks: {
          onInit: (field: FormlyFieldConfig): void => {
            field.options.fieldChanges
              .pipe(
                filter((e) => e.type === 'valueChanges' && e.field === field),
              )
              .subscribe((e) => {
                gkMapSheetFormComponent.handleMapSheetResolutionChange(
                  (e.value as Scale).resolution,
                );
              });
          },
        },
      },
    ];

    return {
      fields,
      options: {
        formState: {
          papers: gkMapSheetFormComponent.mapState.toolbarState.papers,
        },
      },
    };
  }
}
