@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:</span
        >
        <span gkControlValue [showLabel]="controlConfig.showLabel">
          <button
            type="button"
            class="btn btn-link p-0"
            (click)="openModal(modalTemplate)"
            [title]="getValue()"
            >
            {{ getButtonText() }}
          </button>
        </span>
      </div>
    }

    <ng-template #modalTemplate let-close="close('close')">
      <div class="modal-header">
        <h4 class="modal-title">
          <gk-translated-label
            [configLabel]="controlConfig.label"
          ></gk-translated-label>
        </h4>
        <button
          type="button"
          class="btn-close"
          attr.aria-label="{{ 'GK.DYNAMIC_LIST.CLOSE' | translate }}"
          [title]="'GK.DYNAMIC_LIST.CLOSE' | translate"
          (click)="(close)"
        ></button>
      </div>
      <div class="modal-body">
        <span>{{ getValue() }}</span>
      </div>
    </ng-template>
