import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  map,
  Observable,
  Subject,
  Subscription,
  tap,
} from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { PerunResponseContainer } from '../../utils/perun-api.model';
import { GkKendoDataBindingDirective } from '../gk-kendo-data-binding.directive';
import {
  GkKendoGridDataResult,
  GkKendoGridItem,
  GkKendoGridSelection,
} from '../gk-kendo-grid/gk-kendo-grid.model';

export abstract class GridDataService<T, U = void> extends BehaviorSubject<
  GkKendoGridDataResult<T, U>
> {
  public $loading = new BehaviorSubject<boolean>(false);
  public $count = new BehaviorSubject<number>(0);
  public $hiddenColumns = new BehaviorSubject<string[]>([]);
  public $selection = new BehaviorSubject<
    GkKendoGridSelection<T, U> | undefined
  >(undefined);
  public $gridDataBound = new Subject<GkKendoGridItem<T, U>[]>();
  public $selectedKeys = new ReplaySubject<any[]>(1);
  public mapGridData: (
    data: GkKendoGridItem<T, U>[],
  ) => GkKendoGridItem<T, U>[];
  componentInstance: any;
  dataBindingDirective: GkKendoDataBindingDirective<T, U>;
  protected http = inject(HttpClient);
  protected toastr = inject(ToastrService);
  protected initialState: State;
  private abort$ = new Subject<void>();

  protected constructor(
    public url?: string,
    public requestType: 'POST' | 'GET' = 'POST',
    public apiResponseMapFn?: (data: T[]) => U extends void ? T[] : U[],
  ) {
    super({ data: [] as never, total: 0 });
  }

  public queryByState<S extends State>(currentState: S): Subscription {
    const currentGridDataOperationsState = this.dataBindingDirective.getState();

    console.log('initialState', this.initialState);
    console.log('currentState', currentState);
    console.log(
      'currentGridDataOperationsState',
      currentGridDataOperationsState,
    );

    const mergedGridQueryState = _.mergeWith(
      {},
      currentState,
      this.initialState,
      (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return _.unionBy(objValue, srcValue, 'field');
        }
        return undefined;
      },
    );

    if (mergedGridQueryState.sort?.length) {
      mergedGridQueryState.sort = mergedGridQueryState.sort.filter(
        (sortDescriptor) => sortDescriptor.dir !== undefined,
      );
    }

    return this.fetch(this.url, mergedGridQueryState).subscribe(
      (gridDataResult) => {
        super.next(gridDataResult);
      },
    );
  }

  public queryByUrlParams(url: string): void {
    this.url = url;
    this.fetch(url, undefined).subscribe((gridDataResult) => {
      super.next(gridDataResult);
    });
  }

  public clearGridData(): void {
    this.next({ data: [] as never, total: 0 });
  }

  public cancelRequest(): void {
    this.abort$.next();
    this.$loading.next(false);
  }

  fetch<S extends State>(
    url: string,
    state: S,
  ): Observable<GkKendoGridDataResult<T, U>> {
    setTimeout(() => {
      this.$loading.next(true);
    }, 0);

    return this.handleRequestType(url, state).pipe(
      tap(() => this.$loading.next(false)),
      map((response) => {
        if (typeof response === 'object' && 'Response' in response) {
          if (this.apiResponseMapFn) {
            const mappedData = <U[] | T[]>(
              this.apiResponseMapFn(<T[]>response.Response)
            );
            return <GkKendoGridDataResult<T, U>>{
              data: mappedData,
              total: mappedData.length,
            };
          } else {
            return <GkKendoGridDataResult<T, U>>{
              data: response.Response,
              total: response.TotalCount,
            };
          }
        } else if (typeof response === 'object' && Array.isArray(response)) {
          if (this.apiResponseMapFn) {
            const mappedData = <U[] | T[]>this.apiResponseMapFn(<T[]>response);
            return <GkKendoGridDataResult<T, U>>{
              data: mappedData,
              total: mappedData.length,
            };
          } else {
            return <GkKendoGridDataResult<T, U>>{
              data: response,
              total: response.length,
            };
          }
        } else {
          if (this.apiResponseMapFn) {
            const mappedData = <U[] | T[]>this.apiResponseMapFn(<T[]>response);

            return <GkKendoGridDataResult<T, U>>{
              data: mappedData,
              total: mappedData.length,
            };
          } else {
            console.error('Returned response is not array!', response);
            return <GkKendoGridDataResult<T, U>>{
              data: [],
              total: 0,
            };
          }
        }
      }),
      tap(() => this.$loading.next(false)),
      catchError((err) => {
        this.$loading.next(false);

        return throwError(err);
      }),
    );
  }

  getGetRequest<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  getPostRequest<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }

  // private maybeShowMaxSizeNotification(
  //   data: PerunResponseContainer<T[]> | T[]
  // ): void {
  //   if ('IsThatAll' in data && !data.IsThatAll) {
  //     this.toastr.warning(
  //       `Przekroczono maksymalną wielkość zapytania.<br>
  //                           Wyświetlono: <b>${data.Count}</b> rekordów<br>
  //                           z <b>${data.TotalCount}</b> możliwych.<br>
  //                           Zawęź kryteria.`,
  //       undefined,
  //       {
  //         enableHtml: true,
  //       }
  //     );
  //   }
  // }

  // private maybeShowNoRecordsNotification(
  //   data: PerunResponseContainer<T[]> | T[]
  // ): void {
  //   if ('TotalCount' in data && data.TotalCount === 0) {
  //     this.toastr.warning('Wyszukiwanie nie zwróciło żadnych wyników.');
  //
  //     return;
  //   }
  // }

  rebind(): void {
    this.dataBindingDirective.refreshGrid();
  }

  protected handleRequestType(
    url: string,
    state: State,
  ): Observable<PerunResponseContainer<T> | T[] | T> {
    const request$ =
      this.requestType === 'POST'
        ? this.http.post<PerunResponseContainer<T> | T[] | T>(url, state)
        : this.http.get<PerunResponseContainer<T> | T[] | T>(url);

    return request$.pipe(takeUntil(this.abort$));
  }
}
