import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ColumnHeader } from '../gk-components/table/table.model';
import { BsColor } from '../gk-dynamic-navbar/gk-dynamic-navbar.model';
import { ExtensionMimeType, FileExtension } from '../utils/files/files.model';
import { TriggerId } from './services/control-communication/control-communication.model';
import { DictionaryField } from './services/dictionary/dictionary.model';

export class FilterOperator {
  constructor(
    public filterOperatorType: FilterOperatorType,
    public name: string,
  ) {}
}

export enum FilterOperatorType {
  Equals = 'eq',
  NotEquals = 'neq',
  GreaterThan = 'gt',
  GreaterThanOrEquals = 'gte',
  LessThan = 'lt',
  LessThanOrEquals = 'lte',
  StartsWith = 'startswith',
  EndsWith = 'endswith',
  Contains = 'contains',
  NotContains = 'doesnotcontain',
  Between = 'between',
  Intersects = 'intersects',
  InList = 'inlist',
}

export class DateStructForBetweenOperator {
  constructor(
    public from?: NgbDateStruct,
    public to?: NgbDateStruct,
  ) {}
}

export type FilterRightOperand =
  | string
  | NgbDateStruct
  | DateStructForBetweenOperator
  | DictionaryField;

export type ControlConfigWithCount =
  | ExpandibleTitleControlConfig
  | NestedAccordionControlConfig;

export const isControlConfigWithCount = (
  item: ControlConfig,
): item is ControlConfigWithCount => 'pathToDataArrayCount' in item;

export class Filter {
  constructor(
    public columnConfig: ColumnConfig,
    public operator?: FilterOperator,
    public rightOperand?: FilterRightOperand,
  ) {}
}

export enum CellSize {
  BoolSize = 'cell--bool-size',
  DateTime = 'cell--date-time-size',
  TextSmall = 'cell--text-small-size',
  TextBig = 'cell--text-big-size',
}

export enum SortType {
  None = 0,
  Asc = 1,
  Desc = 2,
}

export class ColumnConfig {
  constructor(
    public sortable: boolean,
    public currentSortType: SortType,
    public filterable: boolean,
    public availableOperators: FilterOperator[],
    public controlConfig: ControlConfig,
    public show: boolean,
    public cellSize: CellSize,
    public isCommunityControl?: boolean,
    public isDistrictControl?: boolean,
    public isSheetControl?: boolean,
    public visibleOnlyAtFilter = false,
  ) {}
}

export enum RequestType {
  Post = 'POST',
  Get = 'GET',
  Delete = 'DELETE',
}

export class RequestParam {
  constructor(
    public paramName: string,
    public pathToValue: string,
    public wrapToArray?: boolean,
    public value?: any,
  ) {}
}

export class RequestConfig {
  constructor(
    public url: string,
    public type: RequestType,
    public params: RequestParam[],
    public evaluateUrl?: boolean,
    public controlConfig?: ControlConfig,
    public responseType?: string,
    public pathToDataFromResponse?: string,
    public propertyToWrapWith?: string,
    public successRedirectPath?: string,
    public observe?: 'body' | 'events' | 'response',
  ) {}
}

export enum ListType {
  ListGroup = 'list-group',
  Accordion = 'accordion',
}

export class ListDataConfig {
  constructor(
    public requestConfig: RequestConfig | undefined,
    public controlConfigs: ControlConfig[],
    public listType = ListType.Accordion,
  ) {}
}

export enum ValueType {
  Text = 'text',
  DateTime = 'dateTime',
  Number = 'number',
  Bool = 'boolean',
  Dict = 'dictionary',
  Link = 'link',
  StaticLink = 'staticLink',
  Array = 'array',
  Object = 'object',
  ObjectDtoDetails = 'objectDtoDetails',
  ObjectPosition = 'objectPosition',
  GetDataAndRender = 'getDataAndRender',
  File = 'file',
  OrderPayment = 'order-payment',
  AddRelatedDocument = 'add-related-document',
  TabstripBookmark = 'tabstrip-bookmark',
  DocumentsList = 'documents-list',
  Separator = 'separator',
  Title = 'title',
  NestedAccordion = 'nested-accordion',
  Table = 'table',
  Modal = 'modal',
  TextModal = 'text-modal',
  StaticDict = 'static-dict',
  MapNavigationToMapObject = 'map-navigation-to-map-object',
  InputFile = 'input-file',
  UtilityInfrastructure = 'utility-infrastructure',
  Area = 'area',
  ExpandibleTitle = 'expandible-title',
  FilterAndRender = 'filter-and-render',
  CancelPayment = 'cancel-payment',
  CancelRequest = 'cancel-request',
  AppActionLink = 'app-action-link',
  TextWithButtons = 'text-with-buttons',
  TextWithCallbackButton = 'text-with-callback-button',
  PlaceTypeahead = 'place-typeahead',
  StreetTypeahead = 'street-typeahead',
  DynamicMapObject = 'dynamic-map-object',
  LandParcelNumber = 'landParcelNumber',
}

export enum AreaUnit {
  SquareMeter = 'squareMeter',
  Hectare = 'hectare',
  Are = 'are',
}

export type ApiValue = any;
export type Record = any;

export class GeneralConfig {
  constructor(
    public onPageMaxCountLimit: number,
    public showAccordionBody: boolean,
    public showPagination: boolean,
    public url: string,
    public title: string,
    public lang = 'pl',
    public shouldOpenFirstPanelOnInit?: boolean,
    public shouldOpenRecentlyOpenedPanelOnInit?: boolean,
    public hideFilterCreator?: boolean,
    public filtersAndSearchEventFromParent?: boolean,
    public mainColor: BsColor = BsColor.Primary,
    public mapResponseDataFunction = (val: any) => val,
    public portalId?: number,
  ) {}
}

export class DictionaryConfig {
  constructor(
    public dictType: string,
    public dictName: string,
    public url: string,
    public fieldIdPath: string,
    public fieldNamePath: string,
    public pathToDataFromResponse: string,
    public requestParams?: any,
    public fieldIsArchivalPath?: string,
  ) {}
}

export class ListConfigs {
  constructor(
    public generalConfig: GeneralConfig,
    public dictionaryConfigs: DictionaryConfig[],
    public columnConfigs: ColumnConfig[],
    public listDataConfig: ListDataConfig,
    public messagesListConfig?: MessagesListConfig,
    public communicatorControlConfig?: CommunicatorControlConfig,
  ) {}

  static getInitialStruct(): ListConfigs {
    return {
      generalConfig: {},
      dictionaryConfigs: [],
      columnConfigs: [],
      listDataConfig: {},
      messagesListConfig: {},
    } as ListConfigs;
  }
}

export class MessagesListConfig {
  constructor(
    public controlDataConfig: RequestConfig,
    public columnHeader: ColumnHeader[],
    public messagesListRequestConfig: RequestConfig,
    public parseCallback: (item: any) => any,
    public callbackButtonConfig: CallbackButtonConfig,
  ) {}
}

export class ListState {
  constructor(
    public recentlyOpenedPanelId?: string,
    public initiallyToggledFirstPanel?: boolean,
  ) {}

  static getInitialStruct = (): ListState => new ListState();
}

export type ListData = Record[];

export class DynamicMapObjectControlConfig {
  constructor(
    public idPropertyName = 'MzAtrybutId',
    public namePropertyName = 'MzAtrybutNazwa',
    public valuePropertyName = 'Wartosc',
    public valueFromDictPropertyName = 'MzSloAtrybutNazwa',
    public valueType = ValueType.DynamicMapObject,
  ) {}
}

export class ControlConfig {
  constructor(
    public label: string,
    public pathToValue: string,
    public showLabel: boolean,
    public valueType: ValueType,
    public showIfPaths?: string[],
    public specificSortField?: string,
    public customFilterFieldName?: string,
    public hideIfPathsHasOrContainsSpecificValuesOfProperties?: Array<SpecificValueOfPropertyConf>,
    public placeholder?: string,
  ) {}
}

export class AppActionLinkControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public buttons?: ActionButtonConfig[],
    public override showIfPaths?: string[],
  ) {
    super(label, pathToValue, showLabel, ValueType.AppActionLink, showIfPaths);
  }
}

export enum ActionType {
  Route = 'route',
  ShowOnMap = 'showOnMap',
  ShowParcelAttributes = 'showParcelAttributes',
}

export class SpecificValueOfPropertyConf {
  constructor(
    public pathToValue: string,
    public value: any,
  ) {}
}

export class ActionButtonConfig {
  constructor(
    public routePath: string,
    public label: string,
    public color: BsColor,
    public actionType: ActionType,
    public requestConfig?: RequestConfig,
    public showIfSpecificValueOfProperties?: Array<SpecificValueOfPropertyConf>,
  ) {}
}

export class ArrayControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public override showIfPaths?: string[],
    public arrayElementControlConfig?: ControlConfig,
  ) {
    super(label, pathToValue, showLabel, ValueType.Array, showIfPaths);
  }
}

export class AddRelatedDocumentControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public pathToRequestId: string,
    public override showLabel: boolean,
    public url: string,
    public override showIfPaths?: string[],
    public documentTypeDict?: any[],
    public override hideIfPathsHasOrContainsSpecificValuesOfProperties?: Array<SpecificValueOfPropertyConf>,
  ) {
    super(
      label,
      pathToRequestId,
      showLabel,
      ValueType.AddRelatedDocument,
      showIfPaths,
      undefined,
      undefined,
      hideIfPathsHasOrContainsSpecificValuesOfProperties,
    );
  }
}

export class DictionaryControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public override showIfPaths?: string[],
    public dictionaryType?: string,
    public getStaticDictionaryIfDefined?: () => Observable<DictionaryField[]>,
  ) {
    super(label, pathToValue, showLabel, ValueType.Dict, showIfPaths);
  }
}

export class FileControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public override showIfPaths?: string[],
    public requestConfig?: RequestConfig,
    public pathToFileData?: string,
    public pathToFileNameAndExtension?: string,
    public staticFileName?: string,
  ) {
    super(label, pathToValue, showLabel, ValueType.File, showIfPaths);
  }
}

export class GetDataAndRenderControlConfig extends ControlConfig {
  constructor(
    public requestConfig: RequestConfig,
    public controlConfigsToRender: ControlConfig[],
    public override showIfPaths?: string[],
    public triggerIdToListen?: string,
  ) {
    super('_', '', false, ValueType.GetDataAndRender, showIfPaths);
  }
}

export class ObjectControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public override showIfPaths?: string[],
    public propertiesToOmit?: string[],
  ) {
    super(label, pathToValue, showLabel, ValueType.Object, showIfPaths);
  }
}

export class DocumentsListControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public pathToIdForGet?: string,
    public pathToDocumentsList?: string,
    public override showIfPaths?: string[],
    public getItemsRequestConfig?: RequestConfig,
    public deleteItemRequestConfig?: RequestConfig,
    public downloadItemRequestConfig?: RequestConfig,
    public canDelete?: boolean,
    public canDownload?: boolean,
    public pathToFileType?: string,
    public pathToFileFullName?: string,
    public pathToFileDescription?: string,
    public pathToFileSize?: string,
    public pathToFileAddedDate?: string,
    public hideDeleteButton?: boolean,
    public pathToBlockProperty?: string,
    public blockedDocumentTooltip?: string,
    public pathToDataArrayCountUpdate?: string,
  ) {
    super(
      label,
      pathToIdForGet,
      showLabel,
      ValueType.DocumentsList,
      showIfPaths,
    );
  }
}

export class ControlTextWithButtonsControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public pathToIdForGet: string,
    public override showLabel: boolean,
    public buttons: ButtonControlConfig[],
    public dictConfig?: DictionaryField[],
    public override showIfPaths?: string[],
  ) {
    super(
      label,
      pathToIdForGet,
      showLabel,
      ValueType.TextWithButtons,
      showIfPaths,
    );
  }
}

export class ButtonControlConfig {
  constructor(
    public label: string,
    public requestConfig: RequestConfig,
    public trustedProfileSignRequired: boolean,
    public successLabel?: string,
    public showIfValue?: any,
  ) {}
}

export class ControlTextWithCallbackButtonControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public button: CallbackButtonConfig,
    public override showIfPaths?: string[],
  ) {
    super(
      label,
      undefined,
      showLabel,
      ValueType.TextWithCallbackButton,
      showIfPaths,
    );
  }
}

export class CommunicatorControlConfig {
  constructor(
    public recipientRequestConfig: RequestConfig,
    public messagesRequestConfig?: RequestConfig,
    public addMessageRequestConfig?: RequestConfig,
    public recipientLabelVisible = true,
    public recipientSelectVisible = true,
    public extendedColourCodedMessagesByAuthor = false,
    public isPendingRequestConfig?: RequestConfig,
  ) {}
}

export class CallbackButtonConfig {
  constructor(
    public label: string,
    public callback: (
      dynamicListItem: any,
      buttonStateControl?: ButtonStateControl,
      refreshCallback?: () => any,
    ) => void,
    public showIfValue?: any,
  ) {}
}

export class ButtonStateControl {
  constructor(
    public activeLoadingSpinner: () => void,
    public stopLoadingSpinner: () => void,
  ) {}
}

export class SeparatorControlConfig extends ControlConfig {
  constructor(
    public staticCssWidthValue: string,
    public pathToCssWidthValue: string,
    public showSeparator: boolean,
    public override showIfPaths?: string[],
  ) {
    super(
      staticCssWidthValue,
      pathToCssWidthValue,
      showSeparator,
      ValueType.Separator,
      showIfPaths,
    );
  }
}

export class TitleControlConfig extends ControlConfig {
  constructor(
    public title: string,
    public showTitle: boolean,
    public override showIfPaths?: string[],
  ) {
    super(title, '', showTitle, ValueType.Title, showIfPaths);
  }
}

export class NestedAccordionControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public override showIfPaths: string[],
    public listDataConfig: ListDataConfig,
    public collapsed: boolean,
    public pathToLabel?: string,
    public maxLettersToShow = 20,
    public pathToDataArrayCount?: string,
  ) {
    super(label, '', showLabel, ValueType.NestedAccordion, showIfPaths);
  }
}

export class OrderPaymentControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public portalName: string,
    public pathToAmount: string,
    public override showIfPaths?: string[],
  ) {
    super(label, pathToValue, showLabel, ValueType.OrderPayment, showIfPaths);
  }
}

export class CancelPaymentControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public pathToStatus: string,
    public pathToTransId: string,
    public cancelPaymentApiUrl: string,
    public override showIfPaths?: string[],
    public triggerIdToEmit?: TriggerId,
  ) {
    super(
      label,
      pathToTransId,
      showLabel,
      ValueType.CancelPayment,
      showIfPaths,
    );
  }
}

export class CancelRequestControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public buttonTxt: string,
    public override showLabel: boolean,
    public pathToStatus: string,
    public pathToTransId: string,
    public requestConfig: RequestConfig,
    public override showIfPaths?: string[],
  ) {
    super(
      label,
      pathToTransId,
      showLabel,
      ValueType.CancelRequest,
      showIfPaths,
    );
  }
}

export class TableControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public pathToDataArray: string,
    public override showIfPaths: string[],
    public controlConfigs: ControlConfig[],
    public tableClass = '',
  ) {
    super(label, '', showLabel, ValueType.Table, showIfPaths);
  }
}

export class ModalControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public openModalButtonLabel: string,
    public override showLabel: boolean,
    public override showIfPaths: string[],
    public controlConfig: ControlConfig,
  ) {
    super(label, '', true, ValueType.Modal, showIfPaths);
  }
}

export class TextModalControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public override showIfPaths?: string[],
    public maxLettersToShow = 40,
  ) {
    super(label, pathToValue, showLabel, ValueType.TextModal, showIfPaths);
  }
}

export class StaticDictControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public dictConfig: DictionaryField[],
    public override showIfPaths?: string[],
  ) {
    super(label, pathToValue, showLabel, ValueType.StaticDict, showIfPaths);
  }
}

export class MapNavigateToMapObjectControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public zudpGeometryRequestConfig?: RequestConfig,
    public routeToMapTab?: string,
    public override showIfPaths?: string[],
  ) {
    super(
      label,
      pathToValue,
      showLabel,
      ValueType.MapNavigationToMapObject,
      showIfPaths,
    );
  }
}

export class InputFileControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public pathToRequestId: string,
    public override showLabel: boolean,
    public override showIfPaths: string[],
    public extensions: FileExtension[],
    public mimeTypes: ExtensionMimeType[],
    public apiUrl: string,
  ) {
    super(label, pathToRequestId, showLabel, ValueType.InputFile, showIfPaths);
  }
}

export class AreaControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override pathToValue: string,
    public override showLabel: boolean,
    public unit?: AreaUnit,
    public decimalDigits?: number,
    public areaDefinedInAres?: string,
    public override showIfPaths?: string[],
  ) {
    super(label, pathToValue, showLabel, ValueType.Area, showIfPaths);
  }
}

export class ExpandibleTitleControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public override showIfPaths: string[],
    public listDataConfig: ListDataConfig,
    public collapsed: boolean,
    public pathToLabel?: string,
    public maxLettersToShow = 100,
    public pathToDataArrayCount?: string,
  ) {
    super(label, '', showLabel, ValueType.ExpandibleTitle, showIfPaths);
  }
}

export class BookmarkControlConfig extends ControlConfig {
  constructor(
    public override label: string,
    public override showLabel: boolean,
    public override showIfPaths: string[],
    public bookmarkSectionSettings: BookmarkTabstripConfig[],
  ) {
    super(label, '', showLabel, ValueType.TabstripBookmark, showIfPaths);
  }
}

export class BookmarkTabstripConfig {
  constructor(
    public label: string,
    public columnHeader?: ColumnHeader[],
    public requestConfig?: RequestConfig,
    public isActive?: boolean,
    public isDisabled?: boolean,
  ) {}
}

export enum FilterCondition {
  Equals = 'equals',
}

export class FilterRule {
  constructor(
    public pathToPropertyToCheck: string,
    public filterCondition: FilterCondition,
    public rightOperand: any,
  ) {}
}

export enum FilterLogicOperator {
  And = 'and',
  Or = 'or',
}

export class FilterAndRenderControlConfig extends ControlConfig {
  constructor(
    public pathToData: string,
    public filterRules: FilterRule[],
    public logicBetweenRules: FilterLogicOperator,
    public resultPropertyPath: string,
    public toRenderControlConfigs: ControlConfig[],
    public override showIfPaths: string[] = [],
  ) {
    super('_', pathToData, false, ValueType.FilterAndRender, showIfPaths);
  }
}

export enum AdditionalCouncilState {
  UnfinishedMainConference = 0,
  Available = 1,
  RequestSubmitted = 2,
  FinishedMainConference = 3,
}
