import { Injectable } from '@angular/core';
import { DocType, SqlPattern } from '../doc-type/doc-type.model';
import { DocTypeService } from '../doc-type/doc-type.service';
import { PortalType } from './file-type-matcher.model';

@Injectable()
export class FileTypeMatcherService {
  constructor(public docTypeService: DocTypeService) {}

  private sqlPatternToJsRegExp(sqlPattern: SqlPattern): RegExp | never {
    if (sqlPattern === null) {
      throw new Error('There is no sqlPattern!');
    }

    let pattern = sqlPattern;
    pattern = pattern[0] !== '%' ? `^${pattern}` : pattern;
    pattern = pattern[pattern.length - 1] !== '%' ? `${pattern}$` : pattern;
    pattern = pattern.replace(/%/g, '.*');

    return new RegExp(pattern, 'gi');
  }

  private doesFileNameMatchPattern(
    name: string,
    sqlPattern: SqlPattern
  ): boolean {
    return sqlPattern !== null
      ? this.sqlPatternToJsRegExp(sqlPattern).test(name)
      : false;
  }

  private getDesignerDocType(fileName: string): DocType | undefined {
    return this.docTypeService.designerDocTypes
      .getValue()
      .find((docType) =>
        this.doesFileNameMatchPattern(fileName, docType.sqlPattern)
      );
  }

  private getAnyDocType(fileName: string): DocType | undefined {
    return this.docTypeService.docTypes
      .getValue()
      .find((docType) =>
        this.doesFileNameMatchPattern(fileName, docType.sqlPattern)
      );
  }

  getDocType(
    fileName: string,
    portalType = PortalType.Any
  ): DocType | undefined {
    switch (portalType) {
      case PortalType.Designer:
        return this.getDesignerDocType(fileName);
      default:
        return this.getAnyDocType(fileName);
    }
  }
}
