import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DictionaryField,
  DocSignService,
  EgibObject,
  MapAction,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DocType } from '../../../services/doc-type/doc-type.model';
import { AgriculturalLandUseDocTypeId } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-doc-type/agricultural-land-use-doc-type.model';
import { ExcludingLandUseFromAgriculturalProductionDocTypeService } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-doc-type/agricultural-land-use-doc-type.service';
import {
  AgriculturalLandUseControlName,
  DecisionFormType,
} from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { AgriculturalLandUseFormService } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.service';
import {
  ExcludingLandFromAgriculturalProduction,
  ExcludingLandFromAgriculturalProductionRequestDto,
  ZamowienieDzialkaDto,
} from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { ExclusionPurposeService } from '../../../services/exclusion-purpose/exclusion-purpose.service';
import { FormsStateService } from '../../../services/forms-state/forms-state.service';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import {
  DocFile,
  ParcelTabId,
} from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PortalService } from '../../../services/portal/portal.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-agricultural-land-use-request',
  templateUrl: './agricultural-land-use-request.component.html',
})
export class AgriculturalLandUseRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  override controlName = AgriculturalLandUseControlName;
  proxyDetailsVisible = false;
  exclusionPurposes: DictionaryField[];
  parcelTabId = ParcelTabId;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
    [
      ...this.formsStateService.agriculturalLandUseRequestChosenParcels,
      ...this.formsStateService.agriculturalLandUseRequestExclusionAreas,
    ]
  );
  formValue: ExcludingLandFromAgriculturalProductionRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  chosenFiles: { [key in AgriculturalLandUseDocTypeId]?: File[] } = {};

  constructor(
    private exclusionPurposesService: ExclusionPurposeService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private investorDetailsFormService: InvestorDetailsFormService,
    private toastr: ToastrService,
    private portalService: PortalService,
    protected override newRequestHelperService: NewRequestHelperService,
    protected override docSignService: DocSignService,
    protected override router: Router,
    protected override translateService: TranslateService,
    protected override mapSettingsService: MapSettingsService,
    protected override mapStateService: MapStateService,
    public excludingLandFromAgriculturalProductionFormService: AgriculturalLandUseFormService,
    public excludingLandUseFromAgriculturalProductionDocTypeService: ExcludingLandUseFromAgriculturalProductionDocTypeService,
    public formsStateService: FormsStateService
  ) {
    super(
      undefined,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToExclusionPurposesList();
    this.fetchApplicantMapSettings();
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.subscribeToDecisionFormRadioFormControl();
    this.fetchAndSetPortalId();
    this.fetchWrongFileText();
  }

  createForm(): void {
    this.formGroup =
      this.excludingLandFromAgriculturalProductionFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.VALIDATION.FORM_NOT_VALID_ERROR'
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation)
      );
  }

  subscribeToExclusionPurposesList(): void {
    this.exclusionPurposesService.exclusionPurposes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((exclusionPurposes) => {
        this.exclusionPurposes = exclusionPurposes;
      });
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.excludingLandUseFromAgriculturalProductionDocTypeService.getOrUpdateObligatoryDocTypes(
          proxyDetailsChecked
        );
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  fetchAndSetPortalId(): void {
    this.portalService.portalIds
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalIds) => {
        this.portalId =
          portalIds.excludingLandFromAgriculturalProductionStageFirst;
      });
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean
  ): void {
    if (proxyDetailsChecked) {
      this.formGroup.addControl(
        AgriculturalLandUseControlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup()
      );
    } else {
      this.formGroup.removeControl(AgriculturalLandUseControlName.ProxyDetails);
    }
    this.toggleProxyDetails();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewAgriculturalLandUseRequestParcelsAndExclusionArea,
      this.mapStateService.getViewState(
        MapId.NewAgriculturalLandUseRequestParcelsAndExclusionArea,
        mapSettings
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewAgriculturalLandUseRequestParcelsAndExclusionArea,
        mapSettings,
        MapPortalName.Applicant
      ),
      [this.mapObjectTableState]
    );
  }

  toggleProxyDetails(): void {
    this.proxyDetailsVisible = !this.proxyDetailsVisible;
  }

  addUniqueParcelsToList(parcelsToAdd: EgibObject[]): void {
    this.handleMapAction(
      new MapAction(MapObjectTableActionType.AddToExisting, parcelsToAdd)
    );
  }

  areMapObjectsChosen(): boolean {
    return !_.isEmpty(this.mapState.mapObjectTablesState[0].mapObjects);
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel
    );
  }

  areExclusionAreasChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error()
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.investorDetailsComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            ExcludingLandFromAgriculturalProductionRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments
            ),
            '/api/egib/wniosek/wylGrZProdRolnej/addToSign'
          )
        ),
        takeWhile(() => this.isAlive)
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse}/requests-list`
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.areExclusionAreasChosen() &&
      this.areDocumentsValid()
    );
  }

  getFormValue(): ExcludingLandFromAgriculturalProduction {
    return {
      ...this.formGroup.value,
      landParcels: this.getParcelsMapObjects(),
      exclusionArea: this.getExclusionGeomArray(),
    };
  }

  getParcelsMapObjects(): ZamowienieDzialkaDto[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        )
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data));
  }

  getExclusionGeomArray(): string[] {
    const geom = _.get(
      (this.mapState.mapObjectTablesState[0].mapObjects || undefined).filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon
      )[0],
      'geom'
    );
    return geom ? [geom] : [];
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile)
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.chosenFiles));
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      AgriculturalLandUseControlName.InvestorDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      AgriculturalLandUseControlName.ProxyDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      AgriculturalLandUseControlName.ProxyDetailsCheckbox
    ) as UntypedFormControl;
  }

  areDocumentsValid(): boolean {
    return this.excludingLandUseFromAgriculturalProductionDocTypeService.areObligatoryDocFilesAttached(
      this.getChosenFiles()
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  checkInvestorDetailsFormGroupValidAndFormValidated(): boolean {
    return !this.getInvestorDetailsFormGroup().valid && this.submitted;
  }

  checkProxyDetailsFormGroupValidAndFormValidated(): boolean {
    return (
      this.getProxyDetailsFormGroup() &&
      !this.getProxyDetailsFormGroup().valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  checkExclusionPurposeFormControlValidAndFormValidated(): boolean {
    return (
      !this.formGroup.get(this.controlName.ExclusionPurpose).valid &&
      this.submitted
    );
  }

  checkInvestmentCommencementDateFormControlValidAndFormValidated(): boolean {
    return (
      !this.formGroup.get(this.controlName.InvestmentCommencementDate).valid &&
      this.submitted
    );
  }

  checkParcelsAndExclusionAreasValidAndFormValidated(): boolean {
    return (
      (!this.areParcelsChosen() || !this.areExclusionAreasChosen()) &&
      this.submitted
    );
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<AgriculturalLandUseDocTypeId>
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  areChosenObligatoryDocuments(
    docType: DocType<AgriculturalLandUseDocTypeId>
  ): boolean {
    const files = this.chosenFiles[docType.id];

    return (
      !this.excludingLandUseFromAgriculturalProductionDocTypeService.obligatoryDocTypes.find(
        (obligatoryDocType) => obligatoryDocType.id === docType.id
      ) || !!(files && files.length)
    );
  }

  handleFileInputAction(
    id: AgriculturalLandUseDocTypeId,
    files: DocFile[]
  ): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(id: AgriculturalLandUseDocTypeId, files: DocFile[]): File[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  checkDocTypeIsRequired(id: number): DocType {
    return this.excludingLandUseFromAgriculturalProductionDocTypeService.obligatoryDocTypes.find(
      (obligatoryDocType) => obligatoryDocType.id === id
    );
  }
}
