<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">
      {{
      'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.TITLE'
      | translate
      }}
    </h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          {{
          'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.INVESTOR_DATA'
          | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
            >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isCurrentInvestorNameFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          {{
          'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.CURRENT_INVESTOR_DATA'
          | translate
          }}
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label
                class="text-nowrap"
                [attr.for]="controlName.CurrentInvestorName"
                >
                {{
                'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.CURRENT_INVESTOR_NAME'
                | translate
                }}
              </label>
              <input
                type="text"
                class="form-control form-control-sm"
                [id]="controlName.CurrentInvestorName"
                [name]="controlName.CurrentInvestorName"
                [formControlName]="controlName.CurrentInvestorName"
                />
            </div>
          </div>
        </div>

        @if (!isCurrentInvestorNameFormControlValid() && submitted) {
          <div
            class="card-footer"
            >
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section [formGroup]="getRequestInformationFormGroup()">
      <div
        class="card mb-4"
        [ngClass]="
          !isRequestInformationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          {{
          'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.REQUEST_INFORMATION'
          | translate
          }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label
              class="text-nowrap"
              [attr.for]="requestInformationControlName.WorksType"
              >
              {{
              'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.WORKS_TYPE'
              | translate
              }}
            </label>

            <textarea
              [name]="requestInformationControlName.WorksType"
              [id]="requestInformationControlName.WorksType"
              class="form-control form-control-sm"
              [formControlName]="requestInformationControlName.WorksType"
            ></textarea>
          </div>

          <div class="mb-2">
            <label
              class="text-nowrap"
              [attr.for]="requestInformationControlName.RequestDate"
              >
              {{
              'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.REQUEST_DATE'
              | translate
              }}
            </label>

            <div class="input-group">
              <input
                class="form-control form-control-sm"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="requestInformationControlName.RequestDate"
                [name]="requestInformationControlName.RequestDate"
                [formControlName]="requestInformationControlName.RequestDate"
                ngbDatepicker
                #requestDateDatePicker="ngbDatepicker"
                />
              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="requestDateDatePicker.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        @if (!isRequestInformationFormGroupValid() && submitted) {
          <div
            class="card-footer"
            >
            <div class="alert alert-danger">
              {{ 'FIELDS_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-electronic-correspondence-statement
      [formGroup]="formGroup"
      [submitted]="submitted"
      >
    </app-electronic-correspondence-statement>

    <app-attachments
      [eServiceName]="eServiceName"
      [portalId]="portalId"
      [submitted]="submitted"
      >
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
        'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.DECISION_FORM'
        | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
            />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
            >
            {{
            'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.NON_ELECTRONIC_FORM'
            | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
            />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
            >
            {{
            'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.ELECTRONIC_FORM'
            | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
      [ngClass]="
        checkShippingFormGroupValidAndFormSubmitted()
          ? 'border-danger border-2'
          : 'border-dark'
      "
        >
        <div class="card-header text-white bg-dark">
          {{
          'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.SHIPPING_FORM'
          | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div
            class="card-footer"
            >
            <div class="alert alert-danger">
              {{
              'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.SHIPPING_FORM_REQUIRED'
              | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section class="my-2">
      <app-cautions [portalId]="portalId"></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div
          class="alert alert-danger mt-1"
          >
          <span>
            {{
            'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.PROVIDE_REQUIRED_FIELDS'
            | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary me-2">
        {{
        'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.SUBMIT'
        | translate
        }}
      </button>

      <button
        type="button"
        [routerLink]="['/office-departments-portal/departments']"
        class="btn btn-outline-primary"
        >
        {{
        'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.CANCEL'
        | translate
        }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
