import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map, ReplaySubject } from 'rxjs';
import { DocType, EServiceDocTypeName } from '../../../doc-type/doc-type.model';
import { DocTypeService } from '../../../doc-type/doc-type.service';
import { DocFile } from '../../../new-designer-request/new-designer-request.model';
import { SimplifiedForestManagementPlanDocTypeId } from '../../simplified-forest-management-plan-doc-type/simplified-forest-management-plan-doc-type.model';
import {
  getWaterEquipmentTypeLabelKeyById,
  WaterEquipmentDocTypeId,
} from './water-equipment-doc-type.model';

@Injectable()
export class WaterEquipmentDocTypeService {
  docTypes = new ReplaySubject<DocType[]>();
  obligatoryDocTypes: DocType[];
  obligatoryDocTypesFromApi: DocType[];
  private investorDetailsFormControlChecked: boolean;
  constructor(private docTypeService: DocTypeService) {
    this.fetchDocTypes();
  }

  private fetchDocTypes(): void {
    this.docTypeService
      .getDocTypeForEService(EServiceDocTypeName.EuJednPlyw)
      .pipe(
        map((data) =>
          data.map((element) => ({
            ...element,
            labelKey: getWaterEquipmentTypeLabelKeyById(element.id),
          }))
        )
      )
      .subscribe((data) => this.docTypes.next(data));
    this.setObligatoryDocTypesFromApi();
    this.setObligatoryDocTypes();
  }

  getOrUpdateObligatoryDocTypes(
    investorDetailsFormControlChecked = false
  ): void {
    this.investorDetailsFormControlChecked = investorDetailsFormControlChecked;
    if (investorDetailsFormControlChecked) {
      this.setObligatoryDocTypesWhenInvestorDetailsFormControlChecked();
    } else {
      this.setObligatoryDocTypes();
    }
  }

  private setObligatoryDocTypesFromApi(): void {
    this.docTypes.subscribe(
      (docTypes) =>
        (this.obligatoryDocTypesFromApi = docTypes.filter(
          (docType) => docType.isObligatory
        ))
    );
  }

  private setObligatoryDocTypes(): void {
    this.docTypes.subscribe((docTypes) => {
      this.obligatoryDocTypes = docTypes.filter(
        (docType) =>
          docType.id !==
            SimplifiedForestManagementPlanDocTypeId.PowerOfAttorney &&
          docType.id !== SimplifiedForestManagementPlanDocTypeId.Other
      );
    });
  }

  private setObligatoryDocTypesWhenInvestorDetailsFormControlChecked(): void {
    this.docTypes.subscribe(
      (docTypes) =>
        (this.obligatoryDocTypes = [
          ...docTypes.filter(
            (docType) =>
              docType.id === WaterEquipmentDocTypeId.StampDuty ||
              docType.id === WaterEquipmentDocTypeId.PowerOfAttorney
          ),
          ...this.obligatoryDocTypesFromApi,
        ])
    );
  }

  areObligatoryDocFilesAttached(attachedDocFiles: DocFile[]): boolean {
    if (this.investorDetailsFormControlChecked) {
      return (
        _.every(this.obligatoryDocTypesFromApi, (oblDocType) =>
          _.find(
            attachedDocFiles,
            (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id
          )
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId === WaterEquipmentDocTypeId.RequestStampDuty
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId === WaterEquipmentDocTypeId.PowerOfAttorney
        )
      );
    } else {
      return _.every(this.obligatoryDocTypesFromApi, (oblDocType) =>
        _.find(
          attachedDocFiles,
          (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id
        )
      );
    }
  }
}
