<div class="modal-header">
  <h4 class="modal-title">{{ 'GK.MAP.ATTRIBUTES' | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    [attr.aria-label]="'GK.MAP.CLOSE' | translate"
    [title]="'GK.MAP.CLOSE' | translate"
    (click)="closeModal()"
  ></button>
</div>
@switch (requestStatus) {
  @case (requestStatusEnum.Pending) {
    <div class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  }
  @case (requestStatusEnum.DataFound) {
    <div class="modal-body">
      @if (isLandParcel()) {
        @for (
          controlConfig of mapState.toolsState[toolType]
            .attributesDynamicListConfig.controlConfigs;
          track $index
        ) {
          <gk-control-renderer
            [data]="attributes"
            [controlConfig]="controlConfig"
          ></gk-control-renderer>
        }
      }
      @if (shouldGenerateAttributesDynamically()) {
        <gk-control-renderer
          [controlConfig]="getDynamicAttributesTitleConfig()"
        >
        </gk-control-renderer>
        @for (attribute of attributes; track $index) {
          <gk-control-dynamic-map-object
            [data]="attribute"
            [controlConfig]="dynamicMapObjectControlConfig"
          ></gk-control-dynamic-map-object>
        }
      }
    </div>
  }
  @case (requestStatusEnum.DataNotFound) {
    <span>{{ 'GK.MAP.DATA_NOT_FOUND' | translate }}</span>
  }
}
