import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DictionaryField,
  DocSignService,
  EgibObject,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PzService,
  ToolType,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DocType } from '../../../services/doc-type/doc-type.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { ExclusionPurposeService } from '../../../services/exclusion-purpose/exclusion-purpose.service';
import { FormsStateService } from '../../../services/forms-state/forms-state.service';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import { DocFile } from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PortalService } from '../../../services/portal/portal.service';
import { PremiseIndependenceNotificationDocTypeId } from '../../../services/premise-independence/premise-independence-notification-doc-type/premise-independence-notification-doc-type.model';
import { PremiseIndependenceNotificationDocTypeService } from '../../../services/premise-independence/premise-independence-notification-doc-type/premise-independence-notification-doc-type.service';
import { PremiseIndependenceNotificationControlName } from '../../../services/premise-independence/premise-independence-notification-form/demolition-notification-form.model';
import { PremiseIndependenceNotificationFormService } from '../../../services/premise-independence/premise-independence-notification-form/demolition-notification-form.service';
import {
  PremiseIndependenceNotification,
  PremiseIndependenceNotificationRequestDto,
} from '../../../services/premise-independence/premise-independence-notification/premise-independence-notification.model';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';

@Component({
  selector: 'app-premise-independence-request',
  templateUrl: './premise-independence-notification-request.component.html',
})
export class PremiseIndependenceNotificationRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild('canDeactivateModal')
  canDeactivateModal: NgbModalRef;
  override controlName = PremiseIndependenceNotificationControlName;
  proxyDetailsVisible = false;
  exclusionPurposes: DictionaryField[];
  premiseIndependenceNotificationDocTypeId =
    PremiseIndependenceNotificationDocTypeId;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
    [
      ...this.formsStateService
        .premiseIndependenceNotificationRequestChosenParcels,
    ]
  );
  formValue: PremiseIndependenceNotificationRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  chosenFiles: { [key in PremiseIndependenceNotificationDocTypeId]?: File[] } =
    {};
  override docTypes: DocType[];
  requestSuccessfullySigned = true;

  constructor(
    public simplifiedForestManagementFormService: PremiseIndependenceNotificationFormService,
    public override mapStateService: MapStateService,
    private exclusionPurposesService: ExclusionPurposeService,
    public premiseIndependenceNotificationDocTypeService: PremiseIndependenceNotificationDocTypeService,
    public override mapSettingsService: MapSettingsService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private investorDetailsFormService: InvestorDetailsFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public formsStateService: FormsStateService,
    private modalService: NgbModal,
    private portalService: PortalService,
    public override pzService: PzService
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToExclusionPurposesList();
    this.fetchApplicantMapSettings();
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.subscribeToDecisionFormRadioFormControl();
    this.fetchPremiseIndependenceDocType();
    this.fetchAndSetPortalId();
  }

  createForm(): void {
    this.formGroup = this.simplifiedForestManagementFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.VALIDATION.FORM_NOT_VALID_ERROR'
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation)
      );
  }

  subscribeToExclusionPurposesList(): void {
    this.exclusionPurposesService.exclusionPurposes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((exclusionPurposes) => {
        this.exclusionPurposes = exclusionPurposes;
      });
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.premiseIndependenceNotificationDocTypeService.getOrUpdateObligatoryDocTypes(
          proxyDetailsChecked
        );
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  fetchAndSetPortalId(): void {
    this.portalService.portalIds
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalIds) => {
        this.portalId = portalIds.constructionPortalPremiseIndependenceRequest;
      });
  }

  fetchPremiseIndependenceDocType(): void {
    this.premiseIndependenceNotificationDocTypeService.docTypes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.docTypes = data));
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean
  ): void {
    if (proxyDetailsChecked) {
      this.formGroup.addControl(
        PremiseIndependenceNotificationControlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup()
      );
    } else {
      this.formGroup.removeControl(
        PremiseIndependenceNotificationControlName.ProxyDetails
      );
    }
    this.toggleProxyDetails();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewDemolitionPortalRequest,
      this.mapStateService.getViewState(
        MapId.NewDemolitionPortalRequest,
        mapSettings
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewDemolitionPortalRequest,
        mapSettings,
        MapPortalName.Applicant
      ),
      [this.mapObjectTableState]
    );
  }

  toggleProxyDetails(): void {
    this.proxyDetailsVisible = !this.proxyDetailsVisible;
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error()
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.investorDetailsComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            PremiseIndependenceNotificationRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments
            ),
            '/api/interesant/wniosek/samodzielnoscLokalu/addToSign'
          )
        ),
        takeWhile(() => this.isAlive)
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.ConstructionPortalPremiseIndependenceRequest}/requests-list`
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.areDocumentsValid()
    );
  }

  getFormValue(): PremiseIndependenceNotification {
    return {
      ...this.formGroup.value,
      landParcels: this.getParcelsMapObjects(),
    };
  }

  getParcelsMapObjects(): ZamowienieDzialkaDto[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        )
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data));
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile)
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.chosenFiles));
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      PremiseIndependenceNotificationControlName.InvestorDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      PremiseIndependenceNotificationControlName.ProxyDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      PremiseIndependenceNotificationControlName.ProxyDetailsCheckbox
    ) as UntypedFormControl;
  }

  areDocumentsValid(): boolean {
    return this.premiseIndependenceNotificationDocTypeService.areObligatoryDocFilesAttached(
      this.getChosenFiles()
    );
  }

  checkInvestorDetailsFormGroupValidAndFormValidated(): boolean {
    return !this.getInvestorDetailsFormGroup().valid && this.submitted;
  }

  checkProxyDetailsFormGroupValidAndFormValidated(): boolean {
    return (
      this.getProxyDetailsFormGroup() &&
      !this.getProxyDetailsFormGroup().valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  checkPointLandParcelFormValidated(): boolean {
    return !this.areParcelsChosen() && this.submitted;
  }

  checkPointLandParcelDetailsFormValidated(): boolean {
    const streetFormControl = this.getStreetFormControl();
    return (
      (!this.getObjectNameAndTypeFormControl().valid && this.submitted) ||
      (!this.getPlaceFormControl().valid && this.submitted) ||
      (!(streetFormControl.valid || streetFormControl.disabled) &&
        this.submitted) ||
      (!this.getBuildingNumberFormControl().valid && this.submitted) ||
      (!this.getPostalCodeFormControl().valid && this.submitted)
    );
  }

  getBuildingNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.BuildingNumber
    ) as UntypedFormControl;
  }

  getPostalCodeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.PostalCode
    ) as UntypedFormControl;
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<PremiseIndependenceNotificationDocTypeId>
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  areChosenObligatoryDocuments(
    docType: DocType<PremiseIndependenceNotificationDocTypeId>
  ): boolean {
    const files = this.chosenFiles[docType.id];

    return (
      !this.premiseIndependenceNotificationDocTypeService.obligatoryDocTypes.find(
        (obligatoryDocType) => obligatoryDocType.id === docType.id
      ) || !!(files && files.length)
    );
  }

  handleFileInputAction(
    id: PremiseIndependenceNotificationDocTypeId,
    files: DocFile[]
  ): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(
    id: PremiseIndependenceNotificationDocTypeId,
    files: DocFile[]
  ): File[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  checkDocTypeIsRequired(id: number): DocType {
    return this.premiseIndependenceNotificationDocTypeService.obligatoryDocTypes.find(
      (obligatoryDocType) => obligatoryDocType.id === id
    );
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(formControl && (formControl.dirty || formControl.touched));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  getObjectNameAndTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.ObjectNameAndType
    ) as UntypedFormControl;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.requestSuccessfullySigned) {
      return true;
    }
    return this.isRequestDirty()
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty ||
      this.areChosenDocuments() ||
      this.areParcelsChosen()
    );
  }

  areChosenDocuments(): boolean {
    return this.docTypes.some(
      (docType: DocType<PremiseIndependenceNotificationDocTypeId>) =>
        !!(this.chosenFiles[docType.id] && this.chosenFiles[docType.id].length)
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
