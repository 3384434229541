import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DrawInteractionComponent, MapComponent } from 'ng-openlayers';
import { ToastrService } from 'ngx-toastr';
import { Collection, Feature, Kinetic, MapEvent } from 'ol';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import FullScreen from 'ol/control/FullScreen';
import { Coordinate, createStringXY } from 'ol/coordinate';
import { pointerMove } from 'ol/events/condition';
import { Extent } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import { DrawEvent } from 'ol/interaction/Draw';
import { SelectEvent } from 'ol/interaction/Select';
import { TranslateEvent } from 'ol/interaction/Translate';
import * as olProj from 'ol/proj';
import {
  AddOnType,
  LineStringState,
  MapAction,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPreviewModeState,
  MapSheetFormState,
  MapViewActionType,
  OpenLayersGeometryType,
  PointState,
  PolygonState,
  ProjectionCode,
  ProjectionCodeDefinition,
  SourceActionType,
  SourceType,
  StorageSuffix,
  ToolActionType,
  ToolType,
  Wkt,
} from '../../../models';
import { MapState } from './../../../models/map-state.model';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import { filter, takeWhile } from 'rxjs/operators';
import { scales } from '../../../configs';
import { MapControl } from '../../../controls';
import { MapMeasurementStaticOverlay } from '../../../models/map-measurement-static-overlay.model';
import {
  AttributesFilterService,
  MapRequestsService,
  PolygonTopologyService,
} from '../../../services';
import { MapDrawingActionService } from '../../../services/map-drawing-action/map-drawing-action.service';
import { MapMeasurementService } from '../../../services/map-measurement/map-measurement.service';
import {
  ConversionUtils,
  MapDataStorageUtils,
  MapExtentUtils,
  ShapeUtils,
} from '../../../utils';
import { DomRefService } from '../../../../gk-dynamic-list/services/dom-ref/dom-ref.service';
import { WindowRefService } from '../../../../gk-dynamic-list/services/window-ref.service';
import Style from 'ol/style/Style';

@Component({
  selector: 'gk-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  providers: [
    PolygonTopologyService,
    MapRequestsService,
    AttributesFilterService,
    MapMeasurementService,
  ],
})
export class MapViewComponent
  extends MapControl
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  private isAlive = true;
  @Input() mapFullScreenHTMLDivElement!: HTMLDivElement;
  @ViewChild('map') map!: MapComponent;
  @ViewChild('interactionDraw') interactionDraw: DrawInteractionComponent;
  sketch: Geometry;
  center: Coordinate;
  fullDefaultExtent: Extent;
  movingPointerCoordinate: Coordinate;
  wkt: Wkt;
  translationStart: Coordinate;
  translationEnd: Coordinate;
  kinetic = new Kinetic(-0.035, 0.05, 100);
  coordinateFormat = createStringXY(1);
  resolutions: number[] = scales.map((scale) => scale.resolution).reverse();
  pointerMoveSelection = pointerMove;
  areProjectionDefinitionsSet = false;
  movableFeatures = new Collection<Feature>();
  editableFeatures = new Collection<Feature>();
  addOnTypeEnum = AddOnType;
  sourceTypesWithTranslation = [SourceType.MapSheetForm];
  toolTypesWithSearchOptionEnabled = [
    ToolType.Building,
    ToolType.ClassificationContour,
    ToolType.District,
    ToolType.LandParcel,
    ToolType.LandUse,
    ToolType.ControlPoint,
    ToolType.BoundaryPoint,
  ];
  shouldLoadGeometryLayers = false;
  drawingInProgress = false;
  multiObjectResponseWarnTranslation = '';
  fullscreenModeText = '';
  isDrawingEnabled = true;

  constructor(
    protected translateService: TranslateService,
    private mapRequestsService: MapRequestsService,
    private attributesFilterService: AttributesFilterService,
    private polygonTopologyService: PolygonTopologyService,
    private mapDrawingActionService: MapDrawingActionService,
    private toastrService: ToastrService,
    private dom: DomRefService,
    private windowRefService: WindowRefService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  private setProjectionDefinitions(): void {
    proj4.defs(
      ProjectionCode.Pl2000zone5,
      ProjectionCodeDefinition.Pl2000zone5,
    );
    proj4.defs(
      ProjectionCode.Pl2000zone6,
      ProjectionCodeDefinition.Pl2000zone6,
    );
    proj4.defs(
      ProjectionCode.Pl2000zone7,
      ProjectionCodeDefinition.Pl2000zone7,
    );
    proj4.defs(
      ProjectionCode.Pl2000zone8,
      ProjectionCodeDefinition.Pl2000zone8,
    );
    register(proj4);
    this.areProjectionDefinitionsSet = true;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.handleMapFitting(
      _.get(simpleChanges['mapState'], 'currentValue.viewState.extentToFitTo'),
    );
    this.handleTableFeatureEdition(
      _.get(
        simpleChanges['mapState'],
        `previousValue.mapObjectTablesState[${this.mapState.mapObjectTableStateCurrentIndex}].editedMapObject`,
      ),
      _.get(
        simpleChanges['mapState'],
        `currentValue.mapObjectTablesState[${this.mapState.mapObjectTableStateCurrentIndex}].editedMapObject`,
      ),
    );
    if (!this.isEservicePortal()) {
      this.handleMapPreviewMode(
        _.get(
          simpleChanges['mapState'],
          'currentValue.viewState.previewModeState.enabled',
        ),
      );
    }
  }

  handleMapPreviewMode(isPreviewMode: boolean): void {
    if (isPreviewMode) {
      this.setMapToFront();
    } else {
      this.setMapToBack();
    }
  }

  ngOnInit(): void {
    this.fetchMultiObjectResponseWarnNotificationText();
    this.fetchFullscreenModeText();
    this.setProjectionDefinitions();
    this.setInitialGeolocationProperties();
    this.checkIfDrawingShouldBreak();
    this.checkIfDrawingComponentShouldBeRecreated();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.map.instance.updateSize();
    }, 500);
    const fullscreenControl = new FullScreen({
      source: this.mapFullScreenHTMLDivElement,
      tipLabel: this.fullscreenModeText,
    });

    this.map.instance.addControl(fullscreenControl);
  }

  fetchFullscreenModeText(): void {
    this.translateService
      .get('GK.MAP.FULL_SCREEN_MODE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.fullscreenModeText = translation));
  }

  fetchMultiObjectResponseWarnNotificationText(): void {
    this.translateService
      .get('GK.MAP.MULTI_OBJECT_SEARCH_WARN')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) =>
          (this.multiObjectResponseWarnTranslation = translation),
      );
  }

  setInitialGeolocationProperties(): void {
    this.center = MapExtentUtils.getMapCenter(
      this.mapState.viewState.currentNativeExtent,
    );
    this.fullDefaultExtent = ConversionUtils.getTransformedExtent(
      this.mapState.viewState.fullNativeExtent,
      this.mapState.viewState.nativeProjectionCode,
      ProjectionCode.PseudoMercator,
    );
  }

  checkIfDrawingShouldBreak(): void {
    this.mapDrawingActionService.breakDrawing$
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe(() => {
        this.drawingInProgress = false;
        this.cancelInteractionDraw();
      });
  }

  checkIfDrawingComponentShouldBeRecreated(): void {
    this.mapDrawingActionService.resetDrawing$
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe(() => {
        this.recreateDrawingComponent();
      });
  }

  recreateDrawingComponent(): void {
    this.isDrawingEnabled = false;
    this.changeDetectorRef.detectChanges();
    this.isDrawingEnabled = true;
  }

  handleMapFitting(extentToFitTo: Extent): void {
    if (
      extentToFitTo &&
      _.every(extentToFitTo, _.isFinite) &&
      this.areProjectionDefinitionsSet
    ) {
      const extentInDefaultProjection = ConversionUtils.getTransformedExtent(
        extentToFitTo,
        this.mapState.viewState.nativeProjectionCode,
        ProjectionCode.PseudoMercator,
      );
      this.map.instance.getView().fit(extentInDefaultProjection);
      this.dispatch.emit(new MapAction(MapViewActionType.ExtentToFitToChange));
    }
  }

  handleTableFeatureEdition(
    previousEditedMapObject: MapObject,
    currentEditedMapObject: MapObject,
  ): void {
    if (
      !currentEditedMapObject ||
      !_.isEqual(previousEditedMapObject, currentEditedMapObject)
    ) {
      this.editableFeatures.clear();
    }
  }

  handleMoveEnd(mapEvent: MapEvent): void {
    this.updateMapExtent(mapEvent);
    this.updateResolution(mapEvent);
  }

  updateMapExtent(mapEvent: MapEvent): void {
    const newMapExtent = ConversionUtils.getTransformedExtent(
      mapEvent.map.getView().calculateExtent(mapEvent.map.getSize()),
      ProjectionCode.PseudoMercator,
      this.mapState.viewState.nativeProjectionCode,
    );

    MapDataStorageUtils.setValue(
      this.mapState.id,
      StorageSuffix.MapExtent,
      newMapExtent,
    );

    this.dispatch.emit(
      new MapAction(
        MapViewActionType.MapExtentChange,
        newMapExtent,
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    );
  }

  updateResolution(mapEvent: MapEvent): void {
    const newResolution = mapEvent.map.getView().getResolution();
    MapDataStorageUtils.setValue(
      this.mapState.id,
      StorageSuffix.Resolution,
      newResolution,
    );
    this.map.instance.getView().setResolution(newResolution);

    this.dispatch.emit(
      new MapAction(MapViewActionType.ResolutionChange, newResolution),
    );
  }

  handleMovingPointer(mapBrowserEvent: MapBrowserEvent<MouseEvent>): void {
    this.movingPointerCoordinate = mapBrowserEvent.coordinate;
  }

  handleDrawStart(drawing: DrawEvent): void {
    const toolType = this.getActiveTool(this.mapState.toolsState);
    this.drawingInProgress = true;
    if (this.isMeasurement(toolType)) {
      this.sketch = drawing.feature.getGeometry();
    }
  }

  handleDrawEnd(drawing: DrawEvent): void {
    const toolType = this.getActiveTool(this.mapState.toolsState);
    const sourceType = this.getActiveSource(this.mapState.toolsState, toolType);
    this.drawingInProgress = false;
    const geometry = ConversionUtils.getGeometryFromDrawing(
      drawing,
      ProjectionCode.PseudoMercator,
      this.mapState.viewState.nativeProjectionCode,
    );
    if (
      this.isPolygon() &&
      !this.isValidNumberOfPolygonVertices(
        geometry as MultiPolygon,
        toolType,
        sourceType,
      )
    ) {
      return;
    }
    this.wkt = ConversionUtils.getWktFromGeometry(geometry);

    this.dispatch.emit(
      new MapAction(SourceActionType.LastWktChange, {
        value: this.wkt,
        options: { toolType, sourceType },
      }),
    );

    this.handleToolOptions(toolType, sourceType);
    this.addMeasurementStaticOverlay(toolType, sourceType);
  }

  isValidNumberOfPolygonVertices(
    geometry: MultiPolygon,
    toolType: ToolType,
    sourceType: SourceType,
  ): boolean {
    const coordinates = geometry.getCoordinates()[0];
    const isValid = coordinates.length > 3;
    if (!isValid) {
      this.dispatchAlertVisibilityState(true, toolType, sourceType);
    }

    return isValid;
  }

  addMeasurementStaticOverlay(
    toolType: ToolType,
    sourceType: SourceType,
  ): void {
    if (!this.isMeasurement(toolType)) {
      return;
    }
    this.dispatch.emit(
      new MapAction(ToolActionType.AddMeasurementStaticOverlay, {
        value: new MapMeasurementStaticOverlay(
          this.sketch
            .clone()
            .transform(
              this.mapState.viewState.nativeProjectionCode,
              ProjectionCode.PseudoMercator,
            ),
          sourceType,
          this.movingPointerCoordinate,
        ),
        options: { toolType, sourceType },
      }),
    );
  }

  handleToolOptions(toolType: ToolType, sourceType: SourceType): void {
    if (_.includes(this.toolTypesWithSearchOptionEnabled, toolType)) {
      this.searchMapObjects(toolType, sourceType);
      return;
    }

    if (toolType === ToolType.AnyPolygon || toolType === ToolType.Measurement) {
      this.createNewMapObject(toolType, sourceType);
      return;
    }

    if (toolType === ToolType.Info) {
      this.handleMapObjectInfo(toolType, sourceType);
    }
  }

  searchMapObjects(toolType: ToolType, sourceType: SourceType): void {
    this.dispatchTooltipLoaderVisibilityState(true, toolType, sourceType);

    this.mapRequestsService
      .searchMapObjects(
        toolType,
        this.attributesFilterService.getGeometryFilter(this.wkt),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        if (
          this.mapState.toolsState[toolType].singleObjectMode &&
          data.response.length > 1
        ) {
          this.toastrService.warning(this.multiObjectResponseWarnTranslation);
          this.dispatchTooltipLoaderVisibilityState(
            false,
            toolType,
            sourceType,
          );

          return;
        }
        if (this.mapState.clearAllObjectsOnNewObjectSearch) {
          this.dispatch.emit(new MapAction(ToolActionType.ClearAllMapObjects));
          this.dispatch.emit(
            new MapAction(MapObjectTableActionType.DeselectAll),
          );
        }
        this.dispatch.emit(
          new MapAction(ToolActionType.MapObjectsToolChange, {
            value: data.response,
            options: { toolType },
          }),
        );
        this.dispatch.emit(
          new MapAction(
            this.mapState.toolsState[toolType].singleObjectMode
              ? MapObjectTableActionType.AddNew
              : MapObjectTableActionType.AddToExisting,
            data.response,
          ),
        );

        this.dispatchTooltipLoaderVisibilityState(false, toolType, sourceType);
      });
  }

  createNewMapObject(toolType: ToolType, sourceType: SourceType): void {
    this.dispatchTooltipLoaderVisibilityState(true, toolType, sourceType);
    this.dispatchAlertVisibilityState(false, toolType, sourceType);

    switch (sourceType) {
      case SourceType.Polygon:
        this.createPolygon(toolType, sourceType);
        break;
      case SourceType.LineString:
        this.createLineString(toolType, sourceType);
        break;
      default:
        break;
    }
  }

  createPolygon(toolType: ToolType, sourceType: SourceType): void {
    this.polygonTopologyService
      .getPolygonTopologyValidation(this.wkt)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((validationStatus) => {
        if (validationStatus.isValid) {
          this.createAnyGeometryMapObject(
            toolType,
            MapObjectApiType.ExtentOrPolygon,
          );
        } else {
          this.dispatchAlertVisibilityState(true, toolType, sourceType);
        }

        this.dispatchTooltipLoaderVisibilityState(false, toolType, sourceType);
      });
  }

  createLineString(toolType: ToolType, sourceType: SourceType): void {
    this.createAnyGeometryMapObject(toolType);
    this.dispatchTooltipLoaderVisibilityState(false, toolType, sourceType);
  }

  createAnyGeometryMapObject(
    toolType: ToolType,
    mapObjectApiType?: MapObjectApiType,
  ): void {
    const newMapObject = ShapeUtils.getAnyGeometryMapObject(
      this.wkt,
      mapObjectApiType,
    );
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: newMapObject,
        options: { toolType },
      }),
    );
    if (!this.isMeasurement(toolType)) {
      this.dispatch.emit(
        new MapAction(
          this.mapState.toolsState[toolType].singleObjectMode
            ? MapObjectTableActionType.AddNew
            : MapObjectTableActionType.AddToExisting,
          [newMapObject],
        ),
      );
    }
  }

  handleMapObjectInfo(toolType: ToolType, sourceType: SourceType): void {
    this.dispatchActionsForMapObjectInfo(toolType, sourceType);
    this.dispatchPopupLoaderVisibilityState(true, toolType);
    this.mapRequestsService
      .getMapObjectsInfo(this.wkt)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapObjects) => {
        this.dispatch.emit(
          new MapAction(ToolActionType.PreviewedMapObjectsChange, {
            value: mapObjects,
            options: { toolType },
          }),
        );
        this.dispatchPopupLoaderVisibilityState(false, toolType);
      });
  }

  dispatchActionsForMapObjectInfo(
    toolType: ToolType,
    sourceType: SourceType,
  ): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.IsVisiblePopupChange, {
        value: true,
        options: { toolType },
      }),
    );
    this.dispatch.emit(
      new MapAction(SourceActionType.IsVisibleTooltipChange, {
        value: false,
        options: { toolType, sourceType },
      }),
    );
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: [],
        options: { toolType, newSet: [] },
      }),
    );
  }

  dispatchPopupLoaderVisibilityState(
    shouldShow: boolean,
    toolType: ToolType,
  ): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.IsVisiblePopupLoaderChange, {
        value: shouldShow,
        options: { toolType },
      }),
    );
  }

  dispatchAlertVisibilityState(
    shouldShow: boolean,
    toolType: ToolType,
    sourceType: SourceType,
  ): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.IsVisibleAlertChange, {
        value: shouldShow,
        options: { toolType, sourceType },
      }),
    );
  }

  dispatchTooltipLoaderVisibilityState(
    shouldHide: boolean,
    toolType: ToolType,
    sourceType: SourceType,
  ): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.IsVisibleTooltipLoaderChange, {
        value: shouldHide,
        options: { toolType, sourceType },
      }),
    );
  }

  shouldShowTooltip(
    toolType = this.getActiveTool(this.mapState.toolsState),
    sourceType = toolType &&
      this.getActiveSource(this.mapState.toolsState, toolType),
  ): boolean {
    if (!toolType || !sourceType) {
      return false;
    }

    return (
      !!this.movingPointerCoordinate &&
      this.isSourceActive(toolType, sourceType) &&
      this.mapState.toolsState[toolType][sourceType].isTooltipVisible
    );
  }

  shouldShowPopup(toolType: ToolType, sourceType: SourceType): boolean {
    const toolState = this.mapState.toolsState[toolType];
    return (
      this.isToolActive(toolType) &&
      toolState.isPopupVisible &&
      !!(toolState[sourceType] as PointState | LineStringState | PolygonState)
        .lastWkt
    );
  }

  shouldShowPopupControl(toolType: ToolType): boolean {
    return (
      this.isToolActive(toolType) &&
      Object.prototype.hasOwnProperty.call(
        this.mapState.toolsState[toolType],
        'isPopupVisible',
      )
    );
  }

  isToolActive(toolType: ToolType): boolean {
    return this.mapState.toolsState[toolType].isActive;
  }

  isSourceActive(toolType: ToolType, sourceType: SourceType): boolean {
    return this.mapState.toolsState[toolType][sourceType].isActive;
  }

  shouldEnableSelection(): boolean {
    return (
      !!_.get(
        this.getActiveSourceState(this.mapState.toolsState),
        'isSelectionEnabled',
      ) || this.isEditedMapObject()
    );
  }

  isEditedMapObject(): boolean {
    return !!this.getEditedMapObject();
  }

  shouldEnableTranslation(): boolean {
    return !!_.get(
      this.getActiveSourceState(this.mapState.toolsState),
      'isTranslationEnabled',
    );
  }

  handleFeatureSelection(event: SelectEvent): void {
    event.selected.forEach((feature) => {
      const id = feature.getId();

      if (!id) {
        return;
      }

      if (id.toString().startsWith(AddOnType.Table)) {
        const editedMapObject = this.getEditedMapObject();
        if (
          !!editedMapObject &&
          !this.editableFeatures.getLength() &&
          id
            .toString()
            .includes(`${editedMapObject.uuid}-${editedMapObject.type}`)
        ) {
          this.editableFeatures.push(feature);
        }
        return;
      }

      this.movableFeatures.clear();
      if (
        _.includes(this.sourceTypesWithTranslation, id.toString().split('-')[0])
      ) {
        this.movableFeatures.push(feature);
      }
    });
  }

  handleTranslationStart(event: TranslateEvent): void {
    this.translationStart = this.transformCoordinate(event.coordinate);
  }

  handleTranslationEnd(event: TranslateEvent): void {
    this.translationEnd = this.transformCoordinate(event.coordinate);
    const activeTool = this.getActiveTool(this.mapState.toolsState);
    const activeSource = this.getActiveSource(
      this.mapState.toolsState,
      activeTool,
    );

    if (activeSource === SourceType.MapSheetForm) {
      this.handleMapSheetTranslation(activeTool, activeSource);
    }
  }

  transformCoordinate(coordinateToTransform: Coordinate): Coordinate {
    return olProj.transform(
      coordinateToTransform,
      ProjectionCode.PseudoMercator,
      this.mapState.viewState.nativeProjectionCode,
    );
  }

  handleMapSheetTranslation(toolType: ToolType, sourceType: SourceType): void {
    const previousFormValue = (
      this.mapState.toolsState[toolType][sourceType] as MapSheetFormState
    ).formValue;
    const newFormValue = {
      ...previousFormValue,
      center: ShapeUtils.getTranslatedCenter(
        previousFormValue.center,
        this.translationStart,
        this.translationEnd,
      ),
    };

    this.dispatch.emit(
      new MapAction(SourceActionType.FormValueChange, {
        value: newFormValue,
        options: { toolType, sourceType },
      }),
    );

    this.dispatchMapSheetMapObjects(toolType, newFormValue);
  }

  handleTableFeatureModificationStart(): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.IsVisibleTopologyValidationErrorChange,
        false,
      ),
    );
  }

  handleTableFeatureModificationEnd(feature: Feature): void {
    const geometry = this.getTransformedGeometry(feature);
    this.dispatchEditedMapGeometryUpdate(geometry);
    if (this.getEditedMapObject().type === MapObjectApiType.ExtentOrPolygon) {
      this.dispatchEditedMapObjectAreaUpdate(geometry);
    }
    this.dispatchMapObjectsUpdate();
    this.editableFeatures.clear();
  }

  getTransformedGeometry(feature: Feature): Geometry {
    return feature
      .clone()
      .getGeometry()
      .transform(
        ProjectionCode.PseudoMercator,
        this.mapState.viewState.nativeProjectionCode,
      );
  }

  dispatchEditedMapGeometryUpdate(geometry: Geometry): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.EditedMapObjectGeometryUpdate,
        ConversionUtils.getWktFromGeometry(geometry),
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    );
  }

  dispatchEditedMapObjectAreaUpdate(geometry: Geometry): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.EditedMapObjectAreaUpdate,
        ShapeUtils.getArea(geometry as Polygon),
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    );
  }

  dispatchMapObjectsUpdate(): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.MapObjectsUpdate,
        this.getEditedMapObject(),
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    );
  }

  shouldEnableDrawing(): boolean {
    const activeTool = this.getActiveTool(this.mapState.toolsState);
    if (!activeTool) {
      return false;
    }
    const activeSource = this.getActiveSource(
      this.mapState.toolsState,
      activeTool,
    );
    if (!activeSource) {
      return false;
    }

    return (
      this.isSourceActive(activeTool, activeSource) &&
      this.isSourceGeometryType(activeSource) &&
      this.isDrawingEnabled
    );
  }

  isSourceGeometryType(sourceType: SourceType): boolean {
    return _.includes(Object.keys(OpenLayersGeometryType), sourceType);
  }

  loadGeometryLayers(event: boolean): void {
    this.shouldLoadGeometryLayers = event;
  }

  shouldShowGeometryForSpecificMapObjectTableMapObjects(): boolean {
    return (
      this.shouldLoadGeometryLayers &&
      this.mapState.mapObjectTableStateCurrentIndex !== undefined
    );
  }

  isPolygonDrawing(): boolean {
    return this.isPolygon() && this.drawingInProgress;
  }

  isPolygon(): boolean | undefined {
    return (
      this.interactionDraw && this.interactionDraw.type === SourceType.Polygon
    );
  }

  isMeasurement(toolType: ToolType): boolean {
    return this.interactionDraw && toolType === ToolType.Measurement;
  }

  isLineStringDrawing(): boolean {
    return this.isLineString() && this.drawingInProgress;
  }

  isLineString(): boolean | undefined {
    return (
      this.interactionDraw &&
      this.interactionDraw.type === SourceType.LineString
    );
  }

  enterInteractionDraw(): void {
    this.interactionDraw?.instance?.finishDrawing();
  }

  cancelInteractionDraw(): void {
    this.interactionDraw?.instance?.setActive(false);
    this.interactionDraw?.instance?.setActive(true);
  }

  getEditedMapObject(): MapObject {
    return this.getCurrentActiveMapObjectTableState().editedMapObject;
  }

  getCurrentActiveMapObjectTableState(): MapObjectTableState {
    return _.get<MapState, any>(
      this.mapState,
      `mapObjectTablesState[${this.mapState.mapObjectTableStateCurrentIndex}]`,
      {},
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  backScrollIntoView(): void {
    this.mapState.viewState.backScrollIntoViewRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    this.clearBackScrollIntoViewRef();
  }

  clearBackScrollIntoViewRef(): void {
    this.dispatch.emit(
      new MapAction(MapViewActionType.BackScrollIntoViewRefChange, null),
    );
  }

  onGridElementPreviewExit(): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(false),
      ),
    );
    this.maybeExitFromFullscreenMode();
    this.setMapToBack();
  }

  onGridElementPreviewDone(doneCallback: () => void): void {
    if (typeof doneCallback === 'function') {
      doneCallback();
    }
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(false),
      ),
    );
    this.maybeExitFromFullscreenMode();
    this.setMapToBack();
    this.dispatch.emit(new MapAction(ToolActionType.ClearAllMapObjects));
    this.dispatch.emit(new MapAction(MapObjectTableActionType.DeselectAll));
  }

  maybeExitFromFullscreenMode(): void {
    if (this.dom.nativeDom.fullscreenElement) {
      this.dom.nativeDom.exitFullscreen();
    }
  }

  setMapToFront(): void {
    const mapContainer = this.dom.nativeDom.getElementsByClassName(
      'mapContainer',
    )[0] as HTMLElement;
    mapContainer.style.zIndex = '100000000';
    const navbar = this.dom.nativeDom.getElementsByClassName(
      'app-navbar',
    )[0] as HTMLElement;
    navbar.style.zIndex = '100000001';
  }

  setMapToBack(): void {
    const mapContainer = this.dom.nativeDom.getElementsByClassName(
      'mapContainer',
    )[0] as HTMLElement;
    mapContainer.style.zIndex = '';
    const navbar = this.dom.nativeDom.getElementsByClassName(
      'app-navbar',
    )[0] as HTMLElement;
    navbar.style.zIndex = '';
  }

  isEservicePortal(): boolean {
    return (
      !this.windowRefService.nativeWindow.location.href.includes(
        'surveyor-portal',
      ) ||
      this.windowRefService.nativeWindow.location.href.includes(
        'surveyor-portal/new-request',
      )
    );
  }

  onGridElementPreviewCancel(): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(false),
      ),
    );
    this.maybeExitFromFullscreenMode();
    this.setMapToBack();
    this.dispatch.emit(new MapAction(ToolActionType.ClearAllMapObjects));
    this.dispatch.emit(new MapAction(MapObjectTableActionType.DeselectAll));
  }

  getActiveSourceType(): SourceType {
    const toolType = this.getActiveTool(this.mapState.toolsState);
    return this.getActiveSource(this.mapState.toolsState, toolType);
  }

  getDrawInteractionStyle(): Style {
    const toolType = this.getActiveTool(this.mapState.toolsState);

    return this.mapState.toolsState[
      toolType
    ].interactionDrawStyleConfig?.toOpenLayersStyle();
  }

  shouldShowGeometry(): boolean {
    const activeTool = this.getActiveTool(this.mapState.toolsState);
    if (!activeTool) {
      return false;
    }
    const activeSource = this.getActiveSource(
      this.mapState.toolsState,
      activeTool,
    );
    if (!activeSource) {
      return false;
    }

    return (
      this.mapState.toolsState[activeTool].isActive &&
      this.shouldLoadGeometryLayers
    );
  }
}
