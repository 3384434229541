import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DocSignService,
  FormAlertService,
  MapSettingsService,
  MapStateService,
  PzService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DocType } from '../../../services/doc-type/doc-type.model';
import {
  WaterEquipmentDocTypeId,
  WaterEquipmentDocTypeName,
} from '../../../services/environmental-protection/water-requipment-request/water-equipment-doc-type/water-equipment-doc-type.model';
import { WaterEquipmentDocTypeService } from '../../../services/environmental-protection/water-requipment-request/water-equipment-doc-type/water-equipment-doc-type.service';
import { WaterEquipmentControlName } from '../../../services/environmental-protection/water-requipment-request/water-equipment-form/water-equipment-form.model';
import { WaterEquipmentFormService } from '../../../services/environmental-protection/water-requipment-request/water-equipment-form/water-equipment-form.service';
import {
  WaterEquipment,
  WaterEquipmentRequestDto,
} from '../../../services/environmental-protection/water-requipment-request/water-equipment/water-equipment.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import { DocFile } from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PortalService } from '../../../services/portal/portal.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-water-equipment-request',
  templateUrl: './water-equipment-request.component.html',
})
export class WaterEquipmentRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  override controlName = WaterEquipmentControlName;
  proxyDetailsVisible = false;
  formValue: WaterEquipmentRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  chosenFiles: {
    [key in WaterEquipmentDocTypeId]?: DocFile[];
  } = {};
  chosenProofOfEnablementPaymentFiles: DocFile[] = [];
  proxyDetailsChecked: boolean;

  constructor(
    public waterEquipmentFormService: WaterEquipmentFormService,
    public waterEquipmentDocTypeService: WaterEquipmentDocTypeService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private investorDetailsFormService: InvestorDetailsFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override formAlertService: FormAlertService,
    private portalService: PortalService,
    public override pzService: PzService,
    public override mapSettingsService: MapSettingsService,
    public override mapStateService: MapStateService
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.subscribeToDecisionFormRadioFormControl();
    this.fetchWrongFileText();
    this.fetchAndSetPortalId();
  }

  createForm(): void {
    this.formGroup = this.waterEquipmentFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.VALIDATION.FORM_NOT_VALID_ERROR'
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation)
      );
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.proxyDetailsChecked = proxyDetailsChecked;
        this.waterEquipmentDocTypeService.getOrUpdateObligatoryDocTypes(
          proxyDetailsChecked
        );
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  fetchAndSetPortalId(): void {
    this.portalService.portalIds
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalIds) => {
        this.portalId =
          portalIds.environmentalProtectionPortalWaterEquipmentRequest;
      });
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean
  ): void {
    if (proxyDetailsChecked) {
      this.formGroup.addControl(
        WaterEquipmentControlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup()
      );
    } else {
      this.formGroup.removeControl(WaterEquipmentControlName.ProxyDetails);
    }
    this.toggleProxyDetails();
  }

  toggleProxyDetails(): void {
    this.proxyDetailsVisible = !this.proxyDetailsVisible;
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error()
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.investorDetailsComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            WaterEquipmentRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments
            ),
            '/api/interesant/wniosek/nadanieNrSprzetuPlywajacego/addToSign'
          )
        ),
        takeWhile(() => this.isAlive)
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest}/requests-list`
    );
  }

  isRequestValid(): boolean {
    return this.formGroup.valid && this.areDocumentsValid();
  }

  getFormValue(): WaterEquipment {
    return {
      ...this.formGroup.value,
    };
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile)
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.getChosenFilesGroupedByIds()));
  }

  getChosenFilesGroupedByIds(): {
    [key in WaterEquipmentDocTypeId]?: DocFile[];
  } {
    return {
      ...this.chosenFiles,
      [WaterEquipmentDocTypeId.StampDuty]: [
        ...(this.chosenFiles?.[WaterEquipmentDocTypeId.StampDuty] || []),
        ...this.chosenProofOfEnablementPaymentFiles,
      ],
    };
  }

  getBirthDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.BirthDate
    ) as UntypedFormControl;
  }

  getBirthPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.BirthPlace
    ) as UntypedFormControl;
  }

  getWatercraftTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.WatercraftType
    ) as UntypedFormControl;
  }

  getTechnicalConditionStatementCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.TechnicalConditionStatementCheckbox
    ) as UntypedFormControl;
  }

  getCommitmentStatementCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.CommitmentStatementCheckbox
    ) as UntypedFormControl;
  }

  getWatercraftTotalLengthFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.TotalLength
    ) as UntypedFormControl;
  }

  getWatercraftSideHeightFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.SideHeight
    ) as UntypedFormControl;
  }

  getWatercraftLargestWidthFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.SideHeight
    ) as UntypedFormControl;
  }

  getWatercraftSeatsNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.SideHeight
    ) as UntypedFormControl;
  }
  getWatercraftConstructionMaterialTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.ConstructionMaterialType
    ) as UntypedFormControl;
  }

  getWatercraftStagingPointFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.WatercraftType
    ) as UntypedFormControl;
  }

  getWatercraftDriveTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.DriveType
    ) as UntypedFormControl;
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      WaterEquipmentControlName.InvestorDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      WaterEquipmentControlName.ProxyDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      WaterEquipmentControlName.ProxyDetailsCheckbox
    ) as UntypedFormControl;
  }

  areDocumentsValid(): boolean {
    return this.waterEquipmentDocTypeService.areObligatoryDocFilesAttached(
      this.getChosenFiles()
    );
  }

  checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getInvestorDetailsFormGroup().valid && this.submitted) ||
      (!this.getBirthPlaceFormControl().valid && this.submitted) ||
      (!this.getBirthDateFormControl().valid && this.submitted)
    );
  }

  checkWatercraftDimensionsFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getWatercraftTotalLengthFormControl().valid && this.submitted) ||
      (!this.getWatercraftSideHeightFormControl().valid && this.submitted) ||
      (!this.getWatercraftLargestWidthFormControl().valid && this.submitted) ||
      (!this.getWatercraftSeatsNumberFormControl().valid && this.submitted) ||
      (!this.getWatercraftConstructionMaterialTypeFormControl().valid &&
        this.submitted)
    );
  }

  checkWatercraftDataFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getWatercraftTypeFormControl().valid && this.submitted) ||
      (!this.getWatercraftStagingPointFormControl().valid && this.submitted) ||
      (!this.getWatercraftDriveTypeFormControl().valid && this.submitted)
    );
  }

  checkTechnicalConditionStatementCheckboxFormControlValidAndFormValidated(): boolean {
    return (
      !this.getTechnicalConditionStatementCheckboxFormControl().valid &&
      this.submitted
    );
  }

  checkCommitmentStatementCheckboxFormControlValidAndFormValidated(): boolean {
    return (
      !this.getCommitmentStatementCheckboxFormControl().valid && this.submitted
    );
  }

  checkProxyDetailsFormGroupValidAndFormValidated(): boolean {
    return (
      this.getProxyDetailsFormGroup() &&
      !this.getProxyDetailsFormGroup().valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<WaterEquipmentDocTypeId>
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  areChosenObligatoryDocuments(
    docType: DocType<WaterEquipmentDocTypeId>
  ): boolean {
    const files = this.getChosenFilesGroupedByIds()[docType.id];

    return (
      !this.waterEquipmentDocTypeService.obligatoryDocTypes.find(
        (obligatoryDocType) => obligatoryDocType.id === docType.id
      ) || !!(files && files.length)
    );
  }

  handleFileInputAction(id: WaterEquipmentDocTypeId, files: File[]): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(id: WaterEquipmentDocTypeId, files: DocFile[]): DocFile[] {
    return files.map((file) => {
      file.docTypeId = id;
      file.docDescription = WaterEquipmentDocTypeName.RequestStampDuty;

      return file;
    });
  }

  handleProofOfEnablementPaymentFileInputAction(files: DocFile[]): void {
    this.chosenProofOfEnablementPaymentFiles =
      this.getFilesProofOfEnablementPayment(files);
  }

  getFilesProofOfEnablementPayment(files: DocFile[]): DocFile[] {
    return files.map((file) => {
      file.docTypeId = WaterEquipmentDocTypeId.StampDuty;
      file.docDescription = WaterEquipmentDocTypeName.ProxyStampDuty;

      return file;
    });
  }

  isStampDutyDocType(id: WaterEquipmentDocTypeId): boolean {
    return id === WaterEquipmentDocTypeId.StampDuty;
  }

  checkDocTypeIsRequired(id: number): DocType {
    return this.waterEquipmentDocTypeService.obligatoryDocTypes.find(
      (obligatoryDocType) => obligatoryDocType.id === id
    );
  }

  shouldShowPaymentInputInvalidMessage(
    docType: DocType<WaterEquipmentDocTypeId>
  ): boolean {
    return (
      this.submitted &&
      this.proxyDetailsChecked &&
      !this.isChosenProxyStampDutyDocument(docType)
    );
  }

  isChosenProxyStampDutyDocument(
    docType: DocType<WaterEquipmentDocTypeId>
  ): boolean {
    const files = this.getChosenFilesGroupedByIds()[docType.id];

    return files && files.length
      ? !!files.find(
          (file) =>
            file.docDescription === WaterEquipmentDocTypeName.ProxyStampDuty
        )
      : false;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
