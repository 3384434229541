import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DocSignService,
  FormAlertService,
  MapSettingsService,
  MapStateService,
  PzService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DocType } from '../../../services/doc-type/doc-type.model';
import { FishingCardDocTypeId } from '../../../services/environmental-protection/fishing-card-doc-type/fishing-card-doc-type.model';
import { FishingCardDocTypeService } from '../../../services/environmental-protection/fishing-card-doc-type/fishing-card-doc-type.service';
import { FishingCardControlName } from '../../../services/environmental-protection/fishing-card-form/fishing-card-form.model';
import { FishingCardFormService } from '../../../services/environmental-protection/fishing-card-form/fishing-card-form.service';
import {
  FishingCard,
  FishingCardRequestDto,
} from '../../../services/environmental-protection/fishing-card/fishing-card.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import { DocFile } from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PortalService } from '../../../services/portal/portal.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-fishing-card-request',
  templateUrl: './fishing-card-request.component.html',
})
export class FishingCardRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  override controlName = FishingCardControlName;
  proxyDetailsVisible = false;
  formValue: FishingCardRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  chosenFiles: { [key in FishingCardDocTypeId]?: File[] } = {};

  constructor(
    public fishingCardFormService: FishingCardFormService,
    public fishingCardDocTypeService: FishingCardDocTypeService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private investorDetailsFormService: InvestorDetailsFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override formAlertService: FormAlertService,
    private portalService: PortalService,
    public override pzService: PzService,
    public override mapSettingsService: MapSettingsService,
    public override mapStateService: MapStateService
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.subscribeToDecisionFormRadioFormControl();
    this.fetchAndSetPortalId();
    this.fetchWrongFileText();
  }

  createForm(): void {
    this.formGroup = this.fishingCardFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.VALIDATION.FORM_NOT_VALID_ERROR'
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation)
      );
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.fishingCardDocTypeService.getOrUpdateObligatoryDocTypes(
          proxyDetailsChecked
        );
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  fetchAndSetPortalId(): void {
    this.portalService.portalIds
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalIds) => {
        this.portalId =
          portalIds.environmentalProtectionPortalFishingCardRequest;
      });
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean
  ): void {
    if (proxyDetailsChecked) {
      this.formGroup.addControl(
        FishingCardControlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup()
      );
    } else {
      this.formGroup.removeControl(FishingCardControlName.ProxyDetails);
    }
    this.toggleProxyDetails();
  }

  toggleProxyDetails(): void {
    this.proxyDetailsVisible = !this.proxyDetailsVisible;
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error()
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.investorDetailsComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            FishingCardRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments
            ),
            '/api/interesant/wniosek/wydanieKartyWedkarskiej/addToSign'
          )
        ),
        takeWhile(() => this.isAlive)
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalFishingCardRequest}/requests-list`
    );
  }

  isRequestValid(): boolean {
    return this.formGroup.valid && this.areDocumentsValid();
  }

  getFormValue(): FishingCard {
    return {
      ...this.formGroup.value,
    };
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile)
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.chosenFiles));
  }

  getBirthDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FishingCardControlName.BirthDate
    ) as UntypedFormControl;
  }

  getBirthPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FishingCardControlName.BirthPlace
    ) as UntypedFormControl;
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      FishingCardControlName.InvestorDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      FishingCardControlName.ProxyDetails
    ) as UntypedFormGroup;
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FishingCardControlName.ProxyDetailsCheckbox
    ) as UntypedFormControl;
  }

  areDocumentsValid(): boolean {
    return this.fishingCardDocTypeService.areObligatoryDocFilesAttached(
      this.getChosenFiles()
    );
  }

  checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getInvestorDetailsFormGroup().valid && this.submitted) ||
      (!this.getBirthPlaceFormControl().valid && this.submitted) ||
      (!this.getBirthDateFormControl().valid && this.submitted)
    );
  }

  checkProxyDetailsFormGroupValidAndFormValidated(): boolean {
    return (
      this.getProxyDetailsFormGroup() &&
      !this.getProxyDetailsFormGroup().valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<FishingCardDocTypeId>
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  areChosenObligatoryDocuments(
    docType: DocType<FishingCardDocTypeId>
  ): boolean {
    const files = this.chosenFiles[docType.id];

    return (
      !this.fishingCardDocTypeService.obligatoryDocTypes.find(
        (obligatoryDocType) => obligatoryDocType.id === docType.id
      ) || !!(files && files.length)
    );
  }

  handleFileInputAction(id: FishingCardDocTypeId, files: DocFile[]): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(id: FishingCardDocTypeId, files: DocFile[]): File[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  checkDocTypeIsRequired(id: number): DocType {
    return this.fishingCardDocTypeService.obligatoryDocTypes.find(
      (obligatoryDocType) => obligatoryDocType.id === id
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
