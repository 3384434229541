import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ContextMenuModule,
  ContextMenuService,
} from '@perfectmemory/ngx-contextmenu';
import { AngularOpenlayersModule } from 'ng-openlayers';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import { GkDynamicFormModule } from '../gk-dynamic-form/gk-dynamic-form.module';
import { GkDynamicListModule } from '../gk-dynamic-list/gk-dynamic-list.module';
import { MapSettingsService } from '../services/map-settings/map-settings.service';
import {
  AttributesDynamicListComponent,
  AttributesFormComponent,
  ClearButtonComponent,
  FileLoaderFormComponent,
  HelpControlComponent,
  IncreaseRangeByBufferButtonComponent,
  InfoContentComponent,
  LayersTreeButtonComponent,
  LayersTreeSidebarComponent,
  LineStringComponent,
  MapComponent,
  MapGeometryComponent,
  MapObjectTableComponent,
  MapPopupComponent,
  MapSheetFormComponent,
  MapToolbarComponent,
  MapToolComponent,
  MapToolContainerComponent,
  MapTooltipComponent,
  MapViewComponent,
  PaperDropdownComponent,
  PointComponent,
  PolygonComponent,
  PopupControlComponent,
  RangeFromAllParcelsButtonComponent,
  RangeFromSelectedParcelsButtonComponent,
  ScaleDropdownComponent,
  TreeNodeComponent,
  WmsLayersComponent,
} from './components';
import { DownloadRangeModalComponent } from './components/map/map-toolbar/download-range-modal/download-range-modal.component';
import { MapMeasurementResultComponent } from './components/map/map-view/map-measurement-result/map-measurement-result.component';
import { ElementFocusDirective } from './directives';
import { MapStateService } from './services';
import { MapDrawingActionService } from './services/map-drawing-action/map-drawing-action.service';
import { MapPinControlComponent } from './components/map/map-view/map-pin-control/map-pin-control.component';

const components = [
  MapComponent,
  MapViewComponent,
  MapToolbarComponent,
  ScaleDropdownComponent,
  MapTooltipComponent,
  MapGeometryComponent,
  PaperDropdownComponent,
  MapToolbarComponent,
  TreeNodeComponent,
  LayersTreeButtonComponent,
  LayersTreeSidebarComponent,
  WmsLayersComponent,
  MapToolComponent,
  HelpControlComponent,
  AttributesFormComponent,
  MapObjectTableComponent,
  FileLoaderFormComponent,
  MapSheetFormComponent,
  MapToolContainerComponent,
  MapPopupComponent,
  InfoContentComponent,
  AttributesDynamicListComponent,
  PopupControlComponent,
  PointComponent,
  LineStringComponent,
  PolygonComponent,
  ClearButtonComponent,
  RangeFromSelectedParcelsButtonComponent,
  RangeFromAllParcelsButtonComponent,
  IncreaseRangeByBufferButtonComponent,
  DownloadRangeModalComponent,
  MapMeasurementResultComponent,
];

const directives = [ElementFocusDirective];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    AngularOpenlayersModule,
    TranslateModule,
    ReactiveFormsModule,
    GkDynamicFormModule,
    GkDynamicListModule,
    ContextMenuModule,
    GkComponentsModule,
  ],
  declarations: [...components, ...directives, MapPinControlComponent],
  exports: [...components, ...directives],
  providers: [
    ContextMenuService,
    MapSettingsService,
    MapStateService,
    MapDrawingActionService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
})
export class GkMapModule {}
