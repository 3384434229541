<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">{{ 'PROPERTY_LEASE.TITLE' | translate }}</h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          {{ 'PROPERTY_LEASE.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
            >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          {{ 'PROPERTY_LEASE.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{ 'PROPERTY_LEASE.PLACE' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Place"
              [id]="controlName.Place"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.Place"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focusout)="setPlaceByName()"
              />

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div
                class="alert alert-danger"
                >
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Street">
              {{ 'PROPERTY_LEASE.STREET' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Street"
              [id]="controlName.Street"
              class="form-control form-control-sm"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [formControlName]="controlName.Street"
              (focusout)="setStreetByName()"
              [autocomplete]="undefined"
              />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !areAreaFormControlsValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          {{ 'PROPERTY_LEASE.AREA_TITLE' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>

          <div class="mb-2 row">
            <div class="col-auto pe-0">
              <label class="text-nowrap" [attr.for]="controlName.AreaToLease">
                {{ 'PROPERTY_LEASE.AREA' | translate }}
              </label>
            </div>

            <div class="col-auto ps-0">
              <div class="input-group input-group-sm ps-2">
                <input
                  type="number"
                  [name]="controlName.AreaToLease"
                  [id]="controlName.AreaToLease"
                  class="form-control required"
                  aria-required="true"
                  [formControlName]="controlName.AreaToLease"
                  />

                <span class="input-group-text">m²</span>
              </div>
            </div>
          </div>
        </div>

        @if (submitted && !areAreaFormControlsValid()) {
          <div
            class="card-footer"
            >
            @if (!areLandParcelsChosen()) {
              <div class="alert alert-danger mt-2">
                {{ 'PROPERTY_LEASE.LAND_PARCEL_REQUIRED' | translate }}
              </div>
            }
            @if (!isAreaFormControlValid()) {
              <div
                class="alert alert-danger mt-2"
                >
                {{ 'PROPERTY_LEASE.AREA_REQUIRED' | translate }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isPurposeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Purpose">
            {{ 'PROPERTY_LEASE.PURPOSE' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.Purpose"
                [id]="controlName.Purpose"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.Purpose"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isPurposeFormControlValid() && submitted) {
          <div
            class="card-footer"
            >
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          isDateRangeInvalid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
        >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.LeaseDate">
            {{ 'PROPERTY_LEASE.LEASE_DATE' | translate }}
          </label>
        </div>

        <div class="card-body">
          <app-date-range-picker
            (dateRangeChanged)="updateDateRangeFormControl($event)"
            [required]="true"
          ></app-date-range-picker>
        </div>

        @if (isDateRangeInvalid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'PERIOD_INVALID' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments
      [eServiceName]="eServiceName"
      [portalId]="portalId"
      [submitted]="submitted"
      >
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{ 'PROPERTY_LEASE.DECISION_FORM' | translate }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="controlName.DecisionForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
            />

          <label class="form-check-label" [attr.for]="controlName.DecisionForm">
            {{ 'PROPERTY_LEASE.NON_ELECTRONIC_FORM' | translate }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
      [ngClass]="
        checkShippingFormGroupValidAndFormSubmitted()
          ? 'border-danger border-2'
          : 'border-dark'
      "
        >
        <div class="card-header text-white bg-dark">
          {{ 'PROPERTY_LEASE.SHIPPING_FORM' | translate }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [removeDisabledControls]="true"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div
            class="card-footer"
            >
            <div class="alert alert-danger">
              {{ 'PROPERTY_LEASE.SHIPPING_FORM_REQUIRED' | translate }}
            </div>
          </div>
        }
      </section>
    }

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{ 'PROPERTY_LEASE.ADDITIONAL_INFORMATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.Comments"
                [id]="controlName.Comments"
                class="form-control form-control-sm"
                [formControlName]="controlName.Comments"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div
          class="alert alert-danger mt-1"
          >
          <span>{{ 'PROPERTY_LEASE.PROVIDE_REQUIRED_FIELDS' | translate }}</span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section class="my-2">
      <app-cautions [portalId]="portalId"></app-cautions>
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'PROPERTY_LEASE.SUBMIT' | translate }}
      </button>

      <button
        type="button"
        [routerLink]="['/office-departments-portal/departments']"
        class="btn btn-outline-primary"
        >
        {{ 'PROPERTY_LEASE.CANCEL' | translate }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
