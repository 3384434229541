import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  NgZone,
} from '@angular/core';
import {
  ToolBarButtonComponent,
  ToolBarToolComponent,
} from '@progress/kendo-angular-toolbar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  providers: [
    {
      provide: ToolBarToolComponent,
      useExisting: forwardRef(() => GkToolbarTextComponent),
    },
  ],
  selector: 'gk-kendo-toolbar-text',
  template: `
    <ng-template #toolbarTemplate>
      @if (!deviceService.isMobile()) {
        <div
          #toolbarButton
          [tabindex]="tabIndex"
          [ngStyle]="style"
          [ngClass]="gkButtonClass"
          [attr.title]="title"
          [style.background-color]="fillMode ? themeColor : null"
          (blur)="onBlur()"
          size="none"
        >
          <ng-container *ngIf="toolbarOptions.text">
            <div [innerHTML]="boldAfterColon(toolbarOptions.text)"></div>
          </ng-container>
        </div>
      }
    </ng-template>
  `,
})
export class GkToolbarTextComponent extends ToolBarButtonComponent {
  @Input() gkButtonClass: string;

  constructor(
    private sanitizer: DomSanitizer,
    public deviceService: DeviceDetectorService,
    element: ElementRef,
    zone: NgZone,
  ) {
    super(element, zone);
  }

  boldAfterColon(text: string): SafeHtml {
    if (!text) {
      return '';
    }

    const parts = text.split(':');
    if (parts.length > 1) {
      parts[1] = `<b>${parts[1]}</b>`;
    }

    const result = parts.join(':');
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
