import { LandAndMortgageRegisterDepartment } from '../../../gk-dynamic-form/form-fields/form-select/land-and-mortgage-register-department/services/land-and-mortgage-register/land-and-mortgage-register-department.model';
import { LandAndMortgageRegisterDepartmentService } from '../../../gk-dynamic-form/form-fields/form-select/land-and-mortgage-register-department/services/land-and-mortgage-register/land-and-mortgage-register-department.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFieldType, FormFieldWrappers } from '../custom-types/model';
import { ApiFilter } from '../../../gk-dynamic-list/services/services.model';
import { FormlyFieldKendoSelectComponentProps } from '../custom-types/select.type';

export enum LandAndMortgageRegisterFormFieldGroupControlName {
  LandAndMortgageRegisterSearch = 'landAndMortgageRegisterSearch',
  LandAndMortgageRegisterDepartment = 'landAndMortgageRegisterDepartment',
  LandAndMortgageRegisterNumber = 'landAndMortgageRegisterNumber',
}
export interface LandAndMortgageRegisterFormFieldGroupFormModel {
  [LandAndMortgageRegisterFormFieldGroupControlName.LandAndMortgageRegisterDepartment]: LandAndMortgageRegisterDepartment;
  [LandAndMortgageRegisterFormFieldGroupControlName.LandAndMortgageRegisterNumber]: string;
}

export class LandAndMortgageRegisterFormFieldGroupModel {
  constructor(
    public landAndMortgageRegisterDepartment: LandAndMortgageRegisterDepartment,
    public landAndMortgageRegisterNumber: string,
  ) {}

  static getApiFilter(
    model: LandAndMortgageRegisterFormFieldGroupFormModel,
  ): ApiFilter {
    return new ApiFilter('Kw', {
      KwWydzialId: model?.landAndMortgageRegisterDepartment?.id,
      KwNumer: model?.landAndMortgageRegisterNumber,
    });
  }
}

export const getLandAndMortgageRegisterFormFieldGroup = (
  landAndMortgageRegisterDepartmentService: LandAndMortgageRegisterDepartmentService,
): FormlyFieldConfig => ({
  key: LandAndMortgageRegisterFormFieldGroupControlName.LandAndMortgageRegisterSearch,
  wrappers: [FormFieldWrappers.KendoPanel],
  props: {
    label: 'GK.DYNAMIC_FORM.LAND_AND_MORTGAGE_REGISTER',
  },
  fieldGroup: [
    {
      fieldGroupClassName: 'd-flex flex-row column-gap-1',
      fieldGroup: [
        {
          key: LandAndMortgageRegisterFormFieldGroupControlName.LandAndMortgageRegisterDepartment,
          type: FormFieldType.KendoSelect,
          defaultValue: new LandAndMortgageRegisterDepartment(
            null,
            'Wybierz...',
            null,
          ),
          wrappers: [FormFieldWrappers.KendoFormField],
          className: 'flex-1',
          props: {
            label: 'GK.DYNAMIC_FORM.LAND_AND_MORTGAGE_REGISTER_DEPARTMENT',
            options:
              landAndMortgageRegisterDepartmentService.getLandAndMortgageRegisterDepartments(),
            valueProp: 'id',
            labelProp: 'name',
            valuePrimitive: false,
          } as FormlyFieldKendoSelectComponentProps,
        },
        {
          key: LandAndMortgageRegisterFormFieldGroupControlName.LandAndMortgageRegisterNumber,
          type: FormFieldType.KendoInput,
          wrappers: [FormFieldWrappers.KendoFormField],
          className: 'flex-1',
          props: {
            label: 'GK.DYNAMIC_FORM.LAND_AND_MORTGAGE_REGISTER_NUMBER',
          },
        },
      ],
    },
  ],
});
