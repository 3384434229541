<div class="col-12 d-flex flex-column">
  <span class="w-100 fw-bold py-2" tabindex="0" aria-required="true">
    {{ 'DOCS_TABLE.CHOSEN_DOCUMENTS' | translate }}
  </span>

  <gk-file-input
    class="mb-2 bg-color-from-db-for-button-child"
    [(files)]="files"
    [maxSize]="docTypeService.getDesignerDocTypeMaxSize() | async"
  ></gk-file-input>

  @if (files.length) {
    <div class="table-wrapper w-100 mb-2 border">
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="text-truncate" scope="col">
                {{ 'DOCS_TABLE.NAME' | translate }}
              </th>
              <th class="text-truncate" scope="col">
                {{ 'DOCS_TABLE.DOC_TYPE' | translate }}
              </th>
              <th class="text-truncate" scope="col">
                {{ 'DOCS_TABLE.SIZE' | translate }}
              </th>
              <th class="text-truncate" scope="col">
                {{ 'DOCS_TABLE.MAX_SIZE' | translate }}
              </th>
              <th class="text-truncate" scope="col">
                {{ 'DOCS_TABLE.DESCRIPTION' | translate }}
              </th>
              <th class="text-truncate" scope="col">
                {{ 'DOCS_TABLE.REMOVE' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (file of files; track file.name; let i = $index) {
              <tr>
                <th
                  class="text-truncate"
                  [title]="file.name"
                  [ngClass]="{
                    'text-danger': !isCorrectFileNameLength(file.name)
                  }"
                >
                  {{ getShortText(file.name) }}
                </th>
                <td class="text-truncate">
                  <select
                    name="fileType"
                    id="fileType"
                    class="form-select form-select-sm required"
                    aria-required="true"
                    [(ngModel)]="file.docTypeId"
                  >
                    @for (
                      field of docTypeService.designerDocTypes | async;
                      track field.id
                    ) {
                      <option
                        [ngClass]="{ required: field.requiredForDesigner }"
                        [attr.aria-required]="field.requiredForDesigner"
                        [ngValue]="field.id"
                      >
                        {{ field.name }}
                      </option>
                    }
                  </select>
                </td>
                <td
                  class="text-truncate"
                  [ngClass]="{
                    'text-danger':
                      (docTypeService.isCorrectFileSize(file) | async) === false
                  }"
                >
                  {{ getFileSizeString(file) }}
                </td>
                <td class="text-truncate">
                  {{
                    docTypeService.getParsedMaxSizeOfDocType(file.docTypeId)
                      | async
                  }}
                </td>
                <td class="text-truncate">
                  <textarea
                    class="form-control form-control-sm"
                    name="opis"
                    id="opis"
                    [maxlength]="maxLengthDesignerRequest.Description"
                    rows="1"
                    [(ngModel)]="file.docDescription"
                  ></textarea>
                </td>
                <td>
                  <button
                    (click)="removeFile(i)"
                    type="button"
                    class="btn btn-sm btn-danger fa fa-close"
                    [attr.aria-label]="'DOCS_TABLE.REMOVE' | translate"
                    [title]="'DOCS_TABLE.REMOVE' | translate"
                  ></button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }

  <div
    [hidden]="!submitted || areValidDocTypes()"
    class="my-2 alert alert-danger"
  >
    {{ 'DOCS_TABLE.INVALID_DOC_TYPE' | translate }}
  </div>

  <div
    [hidden]="!submitted || areFilesNotEmpty()"
    class="my-2 alert alert-danger"
  >
    {{ 'DOCS_TABLE.FILES_CANT_BE_EMPTY' | translate }}
  </div>

  <div
    [hidden]="
      !submitted ||
      (docTypeService.isCorrectEveryFileSize(files) | async) === true
    "
    class="my-2 alert alert-danger"
  >
    {{ 'DOCS_TABLE.INVALID_DOC_SIZE' | translate }}
  </div>
  <div
    [hidden]="!submitted || isCorrectEveryFileName()"
    class="my-2 alert alert-danger"
  >
    {{ 'DOCS_TABLE.INVALID_DOC_NAME' | translate }}
  </div>

  <div>
    <button
      [hidden]="!files.length"
      type="button"
      class="my-1 btn btn-sm btn-danger"
      (click)="removeAll()"
    >
      {{ 'DOCS_TABLE.REMOVE_ALL' | translate }}
    </button>
  </div>
</div>
