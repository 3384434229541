export class PortalIds {
  constructor(
    public designer = 9,
    public przp = 10,
    public networkServiceOrder = 13,
    public roadExit = 23,
    public laneOccupancyInfrastructureDevices = 21,
    public laneOccupancyBuildingObjects = 22,
    public excludingLandFromAgriculturalProductionStageFirst = 24,
    public excludingLandFromAgriculturalProductionStageSecond = 25,
    public buildingPermitTransfer = 33,
    public laneOccupancyBuildingWorks = 20,
    public environmentalProtectionPortalSimplifiedForestManagementPlanRequest = 26,
    public environmentalProtectionPortalFishingCardRequest = 29,
    public environmentalProtectionPortalWaterEquipmentRequest = 28,
    public environmentalProtectionPortalTreesAndShrubsFellingRequest = 27,
    public constructionPortalConstructionNotificationRequest = 30,
    public constructionPortalDemolitionNotificationRequest = 31,
    public constructionPortalConstructionLogRequest = 32,
    public constructionPortalPremiseIndependenceRequest = 34,
    public planningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest = 35,
    public planningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest = 36,
    public planningDepartmentExtractFromStudy = 37,
    public addressNumberRequestPortal = 15,
    public surveyor = 14,
    public supplementaryParcelPurchase = 40,
    public propertyLease = 41,
    public ownershipConversion = 38,
    public grantingRights = 43,
    public streetNaming = 42,
    public communalPremisePurchase = 39,
    public landAndBuildingRegisterDataChangesNotificationRequest = 44,
    public soilClassificationOfLandNewCarryOutRequest = 45,
    public presentationCommentsLandBuildingRegistryProject = 46,
    public accessToInformationOnEnvironmentAndItsProtection = 47,
    public buildingPermit = 48,
    public demolitionPermit = 49,
    public buildingOrPartUseChange = 50,
    public temporaryStructureBuildingPermit = 51,
    public buildingPermitModificationDecision = 52,
    public notObjectedNotificationRightsObligationsTransfer = 53,
    public plotLandDevArchConstrProjSeparateDecision = 54,
    public adjacentPropertyNeedEnterDecision = 55,
    public singleFamilyBuilding70ConstructionConversion = 56,
    public singleFamilyBuildingConstructionConversion = 57,
    public geodeticAndCartographicServiceDataRequest = 58,
  ) {}
}
