import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  AppStorageService,
  ColorsService,
  DataService,
  DefaultConfigGuestService,
  DefaultConfigService,
  DemonstrationOfApplicantRightService,
  FormAlertService,
  GkModulesModule,
  GkUserSettingsModule,
  LoginService,
  MapSettingsService,
  MapStateService,
  PlannedApplicationMessageService,
  PortalsPermissionsService,
  PzService,
  RecaptchaService,
  ShippingService,
  SystemCitiesDataService,
  SystemConfigBlobGuestService,
  SystemConfigGuestService,
} from '@gk/gk-modules';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppVersionUpdateComponent } from './components/app-version-update/app-version-update.component';
import { EServiceDocTypeService } from './components/attachments/services/e-service-doc-type/e-service-doc-type.service';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarProviderComponent } from './components/navbar-provider/navbar-provider.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { ApiBaseHrefInterceptor } from './http-interceptors/api-base-href.interceptor';
import { AccessToInformationOnEnvironmentAndItsProtectionModule } from './modules/access-to-information-on-environment-and-its-protection/access-to-information-on-environment-and-its-protection.module';
import {
  AddressNumberRequestPortalModule,
  createTranslateLoader,
} from './modules/address-number-request-portal/address-number-request-portal.module';
import { AdjacentPropertyNeedEnterDecisionModule } from './modules/adjacent-property-need-enter-decision/adjacent-property-need-enter-decision.module';
import { BuildingOrPartUseChangeModule } from './modules/building-or-part-use-change/building-or-part-use-change.module';
import { BuildingPermitModificationDecisionModule } from './modules/building-permit-modification-decision/building-permit-modification-decision.module';
import { BuildingPermitTransferPortalModule } from './modules/building-permit-transfer-portal/building-permit-transfer-portal.module';
import { BuildingPermitModule } from './modules/building-permit/building-permit.module';
import { ConstructionIntentionTypeDictionaryService } from './modules/building-permit/services/construction-intention-type-dictionary/construction-intention-type-dictionary.service';
import { CommunalPremisePurchasePortalModule } from './modules/communal-premise-purchase-portal/communal-premise-purchase-portal.module';
import { ConstructionPortalModule } from './modules/construction-portal/construction-portal.module';
import { DemolitionPermitModule } from './modules/demolition-permit/demolition-permit.module';
import { DesignerPortalModule } from './modules/designer-portal/designer-portal.module';
import { EcoPortalModule } from './modules/eco-portal/eco-portal.module';
import { EnvironmentalProtectionPortalModule } from './modules/environmental-protection-portal/environmental-protection-portal.module';
import { ExcludingLandFromAgriculturalProductionPortalModule } from './modules/excluding-land-from-agricultural-production-portal/excluding-land-from-agricultural-production-portal.module';
import { FreeServicesPortalModule } from './modules/free-services-portal/free-services-portal.module';
import { GeodeticAndCartographicServiceDataRequestModule } from './modules/geodetic-and-cartographic-service-data-request/geodetic-and-cartographic-service-data-request.module';
import { GrantingRightsPortalModule } from './modules/granting-rights-portal/granting-rights-portal.module';
import { LandAndBuildingRegisterDataChangesNotificationModule } from './modules/land-and-building-register-data-changes-notification/land-and-building-register-data-changes-notification.module';
import { LaneOccupancyPortalModule } from './modules/lane-occupancy-portal/lane-occupancy-portal.module';
import { NetworkServiceOrderPortalModule } from './modules/network-service-order-portal/network-service-order-portal.module';
import { NotObjectedNotificationRightsObligationsTransferModule } from './modules/not-objected-notification-rights-obligations-transfer/not-objected-notification-rights-obligations-transfer.module';
import { OfficeDepartmentsPortalModule } from './modules/office-departments-portal/office-departments-portal.module';
import { OwnershipConversionPortalModule } from './modules/ownership-conversion-portal/ownership-conversion-portal.module';
import { PlanningDepartmentModule } from './modules/planning-department/planning-department.module';
import { PlotLandDevArchConstrProjSeparateDecisionModule } from './modules/plot-land-dev-arch-constr-proj-separate-decision/plot-land-dev-arch-constr-proj-separate-decision.module';
import { PokPortalModule } from './modules/pok-portal/pok-portal.module';
import { PropertyLeasePortalModule } from './modules/property-lease-portal/property-lease-portal.module';
import { RoadExitPortalModule } from './modules/road-exit-portal/road-exit-portal.module';
import { SingleFamilyBuildingConstructionIntentionTypeDictionaryService } from './modules/single-family-building-70-construction-conversion/services/sfb-construction-intention-type-dictionary/sfb-construction-intention-type-dictionary.service';
import { SingleFamilyBuilding70ConstructionConversionModule } from './modules/single-family-building-70-construction-conversion/single-family-building-70-construction-conversion.module';
import { SingleFamilyBuildingConstructionConversionModule } from './modules/single-family-building-construction-conversion/single-family-building-construction-conversion.module';
import { SoilClassificationOfLandModule } from './modules/soil-classification-of-land/soil-classification-of-land.module';
import { SpatialInformationPortalModule } from './modules/spatial-information-portal/spatial-information-portal.module';
import { StreetNamingPortalModule } from './modules/street-naming-portal/street-naming-portal.module';
import { SupplementaryParcelPurchasePortalModule } from './modules/supplementary-parcel-purchase-portal/supplementary-parcel-purchase-portal.module';
import { DownloadableApplicationsService } from './modules/surveyor-portal/downloadable-application/downloadable-applications.service';
import { TemporaryStructureBuildingPermitModule } from './modules/temporary-structure-building-permit/temporary-structure-building-permit.module';
import { AddressNumberRequestFormService } from './services/address-number-request-form/address-number-request-form.service';
import { AddressService } from './services/address/address.service';
import { AdministrativeDivisionService } from './services/administrative-division/administrative-division.service';
import { AppLocalStorageService } from './services/app-local-storage/app-local-storage.service';
import { AppModeProviderService } from './services/app-mode-provider/app-mode-provider.service';
import { AppTitleService } from './services/app-title/app-title.service';
import { AuthorizedPersonFormService } from './services/authorized-person-form/authorized-person-form.service';
import { AuthorizedPersonToRegistryViewFormService } from './services/authorized-person-to-registry-view-form/authorized-person-to-registry-view-form.service';
import { BuildRoadExitPermissionFormService } from './services/build-road-exit-permission-form/build-road-exit-permission-form.service';
import { BuildingPermitTransferDocTypeService } from './services/building-permit-transfer/building-permit-transfer-doc-type/building-permit-transfer-doc-type.service';
import { BuildingPermitTransferFormService } from './services/building-permit-transfer/building-permit-transfer-form/building-permit-transfer-form.service';
import { CautionsService } from './services/cautions/cautions.service';
import { ClosedAreasService } from './services/closed-areas/closed-areas.service';
import { CommunityService } from './services/community/community.service';
import { ConfigManagerService } from './services/config-manager/config-manager.service';
import { ConnectionAndNetworkService } from './services/connection-and-network/connection-and-network.service';
import { ConstructionLogDocTypeService } from './services/construction-log/construction-log-doc-type/construction-log-doc-type.service';
import { ConstructionLogFormService } from './services/construction-log/construction-log-form/construction-log-form.service';
import { ConstructionNotificationDocTypeService } from './services/construction/construction-notification-doc-type/construction-notification-doc-type.service';
import { ConstructionNotificationFormService } from './services/construction/construction-notification-form/construction-notification-form.service';
import { DemolitionNotificationDocTypeService } from './services/demolition/demolition-notification-doc-type/demolition-notification-doc-type.service';
import { DemolitionNotificationFormService } from './services/demolition/demolition-notification-form/demolition-notification-form.service';
import { DesignerIncomingDocumentsService } from './services/designer-incoming-documents/designer-incoming-documents.service';
import { DesignerSettingsService } from './services/designer-settings/designer-settings.service';
import { DistrictSelectionService } from './services/district-selection/district-selection.service';
import { DocTypeService } from './services/doc-type/doc-type.service';
import { DocumentIdVerificationService } from './services/document-id-verification/document-id-verification.service';
import { DocumentSubjectService } from './services/document-subject/document-subject.service';
import { DocumentTypeService } from './services/document-type/document-type.service';
import { EcoDocumentSearchFormService } from './services/eco-document-search-form/eco-document-search-form.service';
import { EcoDocumentSearchService } from './services/eco-document-search/eco-document-search.service';
import { EcoMapSettingsService } from './services/eco-map-settings/eco-map-settings.service';
import { EcoNewsletterFormService } from './services/eco-newsletter-form/eco-newsletter-form.service';
import { EcoNewsletterService } from './services/eco-newsletter/eco-newsletter.service';
import { FishingCardDocTypeService } from './services/environmental-protection/fishing-card-doc-type/fishing-card-doc-type.service';
import { FishingCardFormService } from './services/environmental-protection/fishing-card-form/fishing-card-form.service';
import { SimplifiedForestManagementFormService } from './services/environmental-protection/simplified-forest-management-form/simplified-forest-management-form.service';
import { SimplifiedForestManagementPlanDocTypeService } from './services/environmental-protection/simplified-forest-management-plan-doc-type/simplified-forest-management-plan-doc-type.service';
import { TreesAndShrubsFellingDocTypeService } from './services/environmental-protection/trees-and-shrubs-felling-doc-type/trees-and-shrubs-felling-doc-type.service';
import { TreesAndShrubsFellingFormService } from './services/environmental-protection/trees-and-shrubs-felling-form/trees-and-shrubs-felling-form.service';
import { WaterEquipmentDocTypeService } from './services/environmental-protection/water-requipment-request/water-equipment-doc-type/water-equipment-doc-type.service';
import { WaterEquipmentFormService } from './services/environmental-protection/water-requipment-request/water-equipment-form/water-equipment-form.service';
import { ExcludingLandUseFromAgriculturalProductionDocTypeService } from './services/excluding-land-from-agricultural-production/agricultural-land-use-doc-type/agricultural-land-use-doc-type.service';
import { AgriculturalLandUseFormService } from './services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.service';
import { NonAgriculturalLandUseDocTypeService } from './services/excluding-land-from-agricultural-production/non-agricultural-land-use-doc-type/non-agricultural-land-use-doc-type.service';
import { NonAgriculturalLandUseFormService } from './services/excluding-land-from-agricultural-production/non-agricultural-land-use-form/non-agricultural-land-use-form.service';
import { ExclusionPurposeService } from './services/exclusion-purpose/exclusion-purpose.service';
import { FileTypeMatcherService } from './services/file-type-matcher/file-type-matcher.service';
import { FormsStateService } from './services/forms-state/forms-state.service';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { InvestorDetailsFormService } from './services/investor-details-form/investor-details-form.service';
import { BuildingObjectsFormService } from './services/lane-occupancy/building-objects-form/building-objects-form.service';
import { BuildingWorksFormService } from './services/lane-occupancy/building-works-form/building-works-form.service';
import { InfrastructureDevicesFormService } from './services/lane-occupancy/infrastructure-devices-form/infrastructure-devices-form.service';
import { NewDesignerRequestService } from './services/new-designer-request/new-designer-request.service';
import { NewRequestHelperService } from './services/new-request-helper/new-request-helper.service';
import { NewRequestService } from './services/new-request/new-request.service';
import { OfficeDepartmentsService } from './services/office-departments/office-departments.service';
import { OwnershipConversionFormService } from './services/ownership-conversion-form/ownership-conversion-form.service';
import { ParcelService } from './services/parcel/parcel.service';
import { PlaceService } from './services/place/place.service';
import { PortalService } from './services/portal/portal.service';
import { PostalCodeService } from './services/postal-code/postal-code.service';
import { PremiseIndependenceNotificationDocTypeService } from './services/premise-independence/premise-independence-notification-doc-type/premise-independence-notification-doc-type.service';
import { PremiseIndependenceNotificationFormService } from './services/premise-independence/premise-independence-notification-form/demolition-notification-form.service';
import { PropertyLeaseFormService } from './services/property-lease-form/property-lease-form.service';
import { PublicRegisterService } from './services/public-register/public-register.service';
import { RequestFileService } from './services/request-file/request-file.service';
import { RequestWorkspaceStateService } from './services/request-workspace-state/request-workspace-state.service';
import { RightsToGrantService } from './services/rights-to-grant/rights-to-grant.service';
import { SessionRefreshTriggerService } from './services/session-refresh-trigger/session-refresh-trigger.service';
import { SessionService } from './services/session/session.service';
import { SettingsService } from './services/settings/settings.service';
import { SharingPurposeService } from './services/sharing-purpose/sharing-purpose.service';
import { ShippingFormService } from './services/shipping-form/shipping-form.service';
import { SupplementaryParcelPurchaseFormService } from './services/supplementary-parcel-purchase-form/supplementary-parcel-purchase-form.service';
import { SwManagerService } from './services/sw-manager/sw-manager.service';
import { SystemCountyBackgroundService } from './services/system-county-background/system-county-background.service';
import { TimerService } from './services/timer/timer.service';
import { UiVerboseService } from './services/ui-verbose/ui-verbose.service';
import { WebPortalService } from './services/web-portal/web-portal.service';
import { WebRequestService } from './services/web-request/web-request.service';
import { WindowRefService } from './services/window-ref/window-ref.service';
import { windowFactory } from './services/window/window.factory';
import { FeeWaiverFormService } from './shared/fee-waiver/fee-waver-form.service';
import { PermissionFormService } from './shared/permission-number-modal/permission-form/permission-form.service';
import { SharedModule } from './shared/shared.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DomRefService } from './services/dom-ref/dom-ref.service';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    AppVersionUpdateComponent,
    NoAccessComponent,
    NavbarProviderComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    SharedModule,
    NgbModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    GrantingRightsPortalModule,
    DesignerPortalModule,
    FreeServicesPortalModule,
    NetworkServiceOrderPortalModule,
    EcoPortalModule,
    RoadExitPortalModule,
    ExcludingLandFromAgriculturalProductionPortalModule,
    EnvironmentalProtectionPortalModule,
    LaneOccupancyPortalModule,
    LandAndBuildingRegisterDataChangesNotificationModule,
    SoilClassificationOfLandModule,
    AccessToInformationOnEnvironmentAndItsProtectionModule,
    BuildingPermitModule,
    DemolitionPermitModule,
    BuildingOrPartUseChangeModule,
    TemporaryStructureBuildingPermitModule,
    BuildingPermitModificationDecisionModule,
    NotObjectedNotificationRightsObligationsTransferModule,
    PlotLandDevArchConstrProjSeparateDecisionModule,
    AdjacentPropertyNeedEnterDecisionModule,
    SingleFamilyBuilding70ConstructionConversionModule,
    SingleFamilyBuildingConstructionConversionModule,
    BuildingPermitTransferPortalModule,
    ConstructionPortalModule,
    OfficeDepartmentsPortalModule,
    SpatialInformationPortalModule,
    PlanningDepartmentModule,
    AddressNumberRequestPortalModule,
    PokPortalModule,
    PropertyLeasePortalModule,
    SupplementaryParcelPurchasePortalModule,
    OwnershipConversionPortalModule,
    StreetNamingPortalModule,
    CommunalPremisePurchasePortalModule,
    GeodeticAndCartographicServiceDataRequestModule,
    AppRoutingModule,
    GkModulesModule,
    GkUserSettingsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    DialogModule,
    WindowModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiBaseHrefInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: 'window', useFactory: windowFactory },
    // { provide: NgbDatepickerI18n, useClass: DateNamesTranslationService },
    SwManagerService,
    DomRefService,
    SettingsService,
    SharingPurposeService,
    NewRequestService,
    CommunityService,
    SessionService,
    ParcelService,
    ConnectionAndNetworkService,
    PlaceService,
    PostalCodeService,
    AddressService,
    NewDesignerRequestService,
    FormsStateService,
    DesignerIncomingDocumentsService,
    ConfigManagerService,
    AppLocalStorageService,
    AppTitleService,
    TimerService,
    RequestWorkspaceStateService,
    DistrictSelectionService,
    RightsToGrantService,
    SessionRefreshTriggerService,
    DocTypeService,
    FileTypeMatcherService,
    ClosedAreasService,
    UiVerboseService,
    MapSettingsService,
    MapStateService,
    NewRequestHelperService,
    EcoDocumentSearchFormService,
    DocumentTypeService,
    DocumentSubjectService,
    EcoDocumentSearchService,
    AdministrativeDivisionService,
    EcoMapSettingsService,
    EcoNewsletterFormService,
    EcoNewsletterService,
    BuildRoadExitPermissionFormService,
    InvestorDetailsFormService,
    AgriculturalLandUseFormService,
    ExclusionPurposeService,
    ShippingFormService,
    ExcludingLandUseFromAgriculturalProductionDocTypeService,
    InfrastructureDevicesFormService,
    PropertyLeaseFormService,
    OwnershipConversionFormService,
    SupplementaryParcelPurchaseFormService,
    BuildingObjectsFormService,
    ExcludingLandUseFromAgriculturalProductionDocTypeService,
    NonAgriculturalLandUseDocTypeService,
    NonAgriculturalLandUseFormService,
    WebPortalService,
    WebRequestService,
    SimplifiedForestManagementFormService,
    SimplifiedForestManagementPlanDocTypeService,
    FishingCardFormService,
    FishingCardDocTypeService,
    BuildingObjectsFormService,
    NonAgriculturalLandUseDocTypeService,
    NonAgriculturalLandUseFormService,
    FishingCardDocTypeService,
    NonAgriculturalLandUseFormService,
    ExcludingLandUseFromAgriculturalProductionDocTypeService,
    BuildingWorksFormService,
    ConstructionNotificationDocTypeService,
    ConstructionNotificationFormService,
    ConstructionNotificationDocTypeService,
    DemolitionNotificationDocTypeService,
    DemolitionNotificationFormService,
    BuildingPermitTransferDocTypeService,
    BuildingPermitTransferFormService,
    PremiseIndependenceNotificationFormService,
    PremiseIndependenceNotificationDocTypeService,
    DemolitionNotificationFormService,
    WaterEquipmentDocTypeService,
    WaterEquipmentFormService,
    TreesAndShrubsFellingFormService,
    TreesAndShrubsFellingDocTypeService,
    ConstructionLogDocTypeService,
    ConstructionLogFormService,
    SystemConfigBlobGuestService,
    SystemConfigGuestService,
    OfficeDepartmentsService,
    PermissionFormService,
    AuthorizedPersonFormService,
    FeeWaiverFormService,
    AddressNumberRequestFormService,
    ShippingService,
    PortalService,
    DesignerSettingsService,
    DocumentIdVerificationService,
    SystemCitiesDataService,
    ColorsService,
    AuthorizedPersonToRegistryViewFormService,
    SystemCountyBackgroundService,
    PublicRegisterService,
    WindowRefService,
    DownloadableApplicationsService,
    AppStorageService,
    PlannedApplicationMessageService,
    CautionsService,
    RequestFileService,
    AppModeProviderService,
    PortalsPermissionsService,
    DataService,
    LoginService,
    DefaultConfigGuestService,
    DefaultConfigService,
    DemonstrationOfApplicantRightService,
    RecaptchaService,
    PzService,
    FormAlertService,
    {
      provide: APP_INITIALIZER,
      useFactory: (recaptchaService: RecaptchaService) => () =>
        firstValueFrom(recaptchaService.getSiteKey()),
      deps: [RecaptchaService],
      multi: true,
    },
    ConstructionIntentionTypeDictionaryService,
    SingleFamilyBuildingConstructionIntentionTypeDictionaryService,
    EServiceDocTypeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
