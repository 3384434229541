@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <button
        type="button"
        class="btn badge rounded-pill btn-color-from-db"
        [title]="getFullLabel()"
        (click)="switchCollapse()"
        [attr.aria-expanded]="!controlConfig.collapsed"
        >
        <span>{{ getButtonLabel() }}</span
          >&nbsp;
          <span [ngClass]="getIconClass()"></span>
        </button>
      </div>
      <div
        gkControlValue
        [showLabel]="controlConfig.showLabel"
        [ngbCollapse]="collapsed"
        >
        @if (!collapsed) {
          <gk-dynamic-accordion-body
            [data]="data"
            [listDataConfig]="controlConfig.listDataConfig"
            [preventLoadedFirstAccordionDataEimtting]="true"
          ></gk-dynamic-accordion-body>
        }
      </div>
    </div>
  }
