import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DemonstrationOfApplicantRightService,
  DocSignService,
  EgibObject,
  FieldId,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { AttachmentsComponent } from '../../../components/attachments/attachments.component';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { EServiceDocTypeName } from '../../../services/doc-type/doc-type.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { PortalService } from '../../../services/portal/portal.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import {
  SCOLNewCarryOutRequestControlName,
  SCOLNewCarryOutRequestFormGroup,
} from '../services/new-carry-out-request-form/new-carry-out-request-form.model';
import { SCOLNewCarryOutRequestFormService } from '../services/new-carry-out-request-form/new-carry-out-request-form.service';
import { SCOLNewCarryOutRequest } from '../services/new-carry-out-request/new-carry-out-request.model';

@Component({
  selector: 'app-scol-new-carry-out-request',
  templateUrl: './new-carry-out-request.component.html',
  styleUrls: ['./new-carry-out-request.component.scss'],
})
export class SCOLNewCarryOutRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = SCOLNewCarryOutRequestControlName;
  override formGroup: FormGroup<SCOLNewCarryOutRequestFormGroup>;
  eServiceName = EServiceDocTypeName.SCOLNewCarryOutRequest;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel],
    true
  );
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private scolNewCarryOutRequestFormService: SCOLNewCarryOutRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    private portalService: PortalService,
    public override pzService: PzService,
    public demonstrationOfApplicantRightService: DemonstrationOfApplicantRightService
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
    this.fetchAndSetPortalId();
  }

  fetchAndSetPortalId(): void {
    this.portalService.portalIds
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalIds) => {
        this.portalId = portalIds.soilClassificationOfLandNewCarryOutRequest;
      });
  }

  createForm(): void {
    this.formGroup = this.scolNewCarryOutRequestFormService.getFormGroup();
    this.subscribeToDecisionFormRadioFormControl();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.AddressNumberRequest)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.SoilClassificationOfLandNewCarryOutRequest,
      this.mapStateService.getViewState(
        MapId.SoilClassificationOfLandNewCarryOutRequest,
        this.mapSettings
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        this.mapSettings.papers,
        undefined,
        undefined,
        true
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.SoilClassificationOfLandNewCarryOutRequest,
        this.mapSettings,
        MapPortalName.Applicant
      ),
      [this.mapObjectTableState]
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areMapObjectsChosen()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles()
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            SCOLNewCarryOutRequest.fromAppToApi(
              this.getFormValue(),
              this.getLandParcels(),
              apiDocuments
            ),
            '/api/interesant/wniosek/gleboznawczaKlasyfikacjaGruntow/addToSign'
          )
        ),
        takeWhile(() => this.isAlive)
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getLandParcels(): EgibObject[] {
    return this.mapState.mapObjectTablesState[0].mapObjects as EgibObject[];
  }

  getFormValue(): SCOLNewCarryOutRequest {
    return this.formGroup.getRawValue();
  }

  isLawPersonFormGroupValid(): boolean {
    return this.getLawPersonFormGroup().valid;
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formGroup.get(this.controlName.LawPerson) as UntypedFormGroup;
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.SoilClassificationOfLandRequest}/requests-list`
    );
  }

  isReasonFormControlValid(): boolean {
    return this.getReasonFormControl().valid;
  }

  getReasonFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.Reason);
  }

  isEnableWorkByPersonFormControlValid(): boolean {
    return this.getEnableWorkByPersonFormControl().valid;
  }

  getEnableWorkByPersonFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.EnableWorkByPerson);
  }

  isDemonstrationOfApplicantRightFormControlValid(): boolean {
    return this.getDemonstrationOfApplicantRightFormControl().valid;
  }

  getDemonstrationOfApplicantRightFormControl(): AbstractControl<FieldId> {
    return this.formGroup.get(this.controlName.DemonstrationOfApplicantRight);
  }

  areMapObjectsChosen(): boolean {
    return !!this.mapState.mapObjectTablesState[0].mapObjects?.length;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
